/* External */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

/* Components */
import FaqsIntroSP from "./../FaqsIntroSP/FaqsIntroSP";
import FaqsContainer from "./../FaqsContainer/FaqsContainer";
import Contact from "./../Contact";
import Footer from "./../Footer";

/* Styles */
import "./SupportPage.scss";

/* Other */
import {
  FAQS_SUPPORT_PROGRAM,
  FAQS_SUPPORT_ACTIVATION,
  FAQS_SUPPORT_REQUIREMENTS,
  FAQS_SUPPORT_DEVICES,
} from "./../../Utils/Constants/Data";
import {
  useNavbar,
  useGetCurrentBreakpoint,
} from "./../../Utils/Functions/Hooks";

const SupportPage = ({ }) => {
  const currentBreakpoint = useGetCurrentBreakpoint(useState, useEffect);
  const currentNavBar = useNavbar(useState, currentBreakpoint);

  return (
    <div className="pp-support-page">
      <Helmet>
        <title>BeHealthy Rewards Program | BeHealthy By Globant X</title>
      </Helmet>
      {currentNavBar}
      <div id={"FaqsIntroSP"}>
        <span className="pp-support-page__section--wrapper">
          <FaqsIntroSP />
        </span>
      </div>
      <div
        id={"Program"}
        className="pp-support-page__section pp-support-page__section--grey"
      >
        <span className="pp-support-page__section--wrapper">
          <FaqsContainer title="Program" obj={FAQS_SUPPORT_PROGRAM} />
        </span>
      </div>
      <div
        id={"Account-Activation"}
        className="pp-support-page__section pp-support-page__section--green"
      >
        <span className="pp-support-page__section--wrapper">
          <FaqsContainer
            title="Account activation"
            obj={FAQS_SUPPORT_ACTIVATION}
          />
        </span>
      </div>
      <div
        id={"Requirements"}
        className="pp-support-page__section pp-support-page__section--grey"
      >
        <span className="pp-support-page__section--wrapper">
          <FaqsContainer title="Requirements" obj={FAQS_SUPPORT_REQUIREMENTS} />
        </span>
      </div>
      <div
        id={"Devices"}
        className="pp-support-page__section pp-support-page__section--green"
      >
        <span className="pp-support-page__section--wrapper">
          <FaqsContainer title="Devices" obj={FAQS_SUPPORT_DEVICES} />
        </span>
      </div>
      <div
        id={"Contact"}
        className="pp-support-page__section pp-support-page__section--on-big"
      >
        <span className="pp-support-page__section--wrapper">
          <Contact />
        </span>
      </div>
      <div
        id={"Footer"}
        className="pp-support-page__section pp-support-page__section--black pp-support-page__section--on-big"
      >
        <span className="pp-support-page__section--wrapper">
          <Footer />
        </span>
      </div>
    </div>
  );
};

export default SupportPage;
