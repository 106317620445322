import React, { useState, useEffect } from 'react';

/* Styles */
import './Stats.scss';

/* Others */
import { STATS } from '../../Utils/Constants/Data';

const Stats = () => {
  return (
    <div className='pp-stats'>
      <h2 className='pp-stats__title'>
        More movement. More Health. More Engagement. Let’s BeHealthy!
      </h2>
      <p className='pp-stats__text'>
        Our <strong>gamified algorithms</strong> drive brand engagement and
        motivate participants who are the least active at enrollment to adopt
        long term healthy behaviors.
      </p>
      <div className='pp-stats__bottom-block'>
        {STATS.map(({ id, src, alt, value, text }) => (
          <div className='pp-stats__bottom-block-section' key={id}>
            <img className='pp-stats__bottom-block-img' src={src} alt={alt} />
            <p className='pp-stats__bottom-block-value'>{value}</p>
            <p className='pp-stats__bottom-block-paragraph'>{text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Stats;
