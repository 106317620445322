/* External */
import React, { useState } from 'react';

/* Components */
import Accordion from './../Accordion/Accordion';

/* Styles */
import './FaqsContainer.scss';

const FaqsContainer = ({ title, obj }) => {
  const [currentlyActiveQuestion, setCurrentlyActiveQuestion] = useState(1);

  const data = [...obj];

  return (
    <div className='pp-faqs-container'>
      <h2 className='pp-faqs-container__title'>{title}</h2>
      <div className='pp-faqs-container__accordion-container'>
        {data.map(({ question, answer, id }, index) => (
          <Accordion
            question={question}
            answer={answer}
            key={id}
            currentlyActiveQuestion={currentlyActiveQuestion}
            setCurrentlyActiveQuestion={setCurrentlyActiveQuestion}
            questionNumber={index + 1}
          />
        ))}
      </div>
    </div>
  );
};

export default FaqsContainer;
