/* External */
import React from 'react';

/* Styles */
import './NavBarMobileToggleButton.scss';

const NavBarMobileToggleButton = ({ navbarOpen, toggleNavBar }) => {
  return (
    <button
      className={`pp-nav-bar-mobile-toggle-button ${
        navbarOpen ? 'pp-nav-bar-mobile-toggle-button--open' : ''
      }`}
      onClick={toggleNavBar}
    >
      <div
        className={`pp-nav-bar-mobile-toggle-button__bar ${
          navbarOpen ? 'pp-nav-bar-mobile-toggle-button__bar--open' : ''
        }`}
      />
      <div
        className={`pp-nav-bar-mobile-toggle-button__bar ${
          navbarOpen ? 'pp-nav-bar-mobile-toggle-button__bar--open' : ''
        }`}
      />
      <div
        className={`pp-nav-bar-mobile-toggle-button__bar ${
          navbarOpen ? 'pp-nav-bar-mobile-toggle-button__bar--open' : ''
        }`}
      />
    </button>
  );
};

export default NavBarMobileToggleButton;
