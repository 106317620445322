/* External */
import React from 'react';
import { Link } from 'react-router-dom';

/* Styles */
import './FaqsIntroSP.scss';

const FaqsIntroSP = () => {
  return (
    <div className='pp-faqs-intro'>
      <div className='pp-faqs-intro__text-container'>
        <h2 className='pp-faqs-intro__title'>
          Any questions? Don't worry! We’ve got answers
        </h2>
        <p className='pp-faqs-intro__text'>
          Here are some of the most frequently asked questions about the Globant
          BeHealthy program.
        </p>
      </div>
      <div className='pp-faqs-intro__links-container'>
        <div className='pp-faqs-intro__links-container--left-on-big'>
          <Link to='#Program'>Program</Link>
          <Link to='#Account-Activation'>Account activation</Link>
        </div>
        <div className='pp-faqs-intro__links-container--right-on-big'>
          <Link to='#Requirements'>Requirements</Link>
          <Link to='#Devices'>Devices</Link>
        </div>
      </div>
    </div>
  );
};

export default FaqsIntroSP;
