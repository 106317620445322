/* External */
import React from 'react';
import { Link } from 'react-router-dom';

/* Styles */
import './CustomButton.scss';

const CustomButton = ({
  filled = false,
  text = '',
  href = '',
  customContainerClass = '',
  customLinkClass = '',
  onClick = () => {},
  isNavigation = false,
}) => {
  const linkComponent = isNavigation ? (
    <Link
      className={`pp-custom-button__btn ${
        filled ? 'pp-custom-button__btn--filled' : ''
      } ${customLinkClass}`}
      to={href}
      onClick={onClick}
    >
      {text}
    </Link>
  ) : (
    <a
      className={`pp-custom-button__btn ${
        filled ? 'pp-custom-button__btn--filled' : ''
      } ${customLinkClass}`}
      href={href}
      target='_blank'
      rel='noreferrer'
      onClick={onClick}
    >
      {text}
    </a>
  );

  return (
    <div className={`pp-custom-button ${customContainerClass}`}>
      {linkComponent}
    </div>
  );
};

export default CustomButton;
