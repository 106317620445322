/* External */
import React from 'react';

/* Styles */
import './DeliveryOptions.scss';

/* Others */
import { DELIVERY_OPTIONS } from './../../Utils/Constants/Data';

const DeliveryOptions = () => {
  const listedDeliveryOptions = DELIVERY_OPTIONS.map((item, index) => (
    <div key={index} className='pp-delivery-options__options-item'>
      <h3 className='pp-delivery-options__options-item-title'>{item.title}</h3>
      <p className='pp-delivery-options__options-item-text'>{item.text}</p>
    </div>
  ));

  return (
    <div className='pp-delivery-options'>
      <h2 className='pp-delivery-options__title'>
        Delivery options to fit your business
      </h2>
      <p className='pp-delivery-options__introduction'>
        BeHealthy is available as quick-to-market iOS and Android white label
        applications, or as an SDK that can be embedded into existing
        applications.
      </p>
      <div className='pp-delivery-options__options-block'>
        {listedDeliveryOptions}
      </div>
    </div>
  );
};

export default DeliveryOptions;
