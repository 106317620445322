/* External */
import React from 'react';

/* Styles */
import './HowItWorksCustomCard.scss';

const HowItWorksCustomCard = ({
  text = '',
  src = '',
  title = '',
  alt = '',
  customClass = '',
}) => {
  return (
    <div className={`pp-how-it-works-custom-card ${customClass}`}>
      <img className='pp-how-it-works-custom-card__img' src={src} alt={alt} />
      <div className='pp-how-it-works-custom-card__right'>
        <span className='pp-how-it-works-custom-card__title'>{title}</span>
        <p className='pp-how-it-works-custom-card__caption'>{text}</p>
      </div>
    </div>
  );
};

export default HowItWorksCustomCard;
