/* External */
import React from 'react';

/* Styles */
import './CookieQuestionContainer.scss';

/* Components */
import CookieQuestions from './../CookieQuestions/CookieQuestions';

const CookieQuestionContainer = ({ cookieQuestions }) => {
  const cookieQuestionData = [...cookieQuestions];

  return (
    <div className='cookie-question-container'>
      {cookieQuestionData.map(({ id, question, answer }, index) => (
        <CookieQuestions
          key={id}
          question={question}
          answer={answer}
          borderBottom={id === 1 || id === 2}
        />
      ))}
    </div>
  );
};

export default CookieQuestionContainer;
