/* External */
import React, { useEffect, useRef } from 'react';
import { Routes, Route, useLocation, Link } from 'react-router-dom';

/* Components */
import Landing from './Components/Landing';
import SupportPage from './Components/SupportPage/SupportPage';
import CookiePage from './Components/CookiePage/CookiePage';
import ThankyouPage from './Components/ThankyouPage/ThankyouPage';
import TermsAndConditionsPage from './Components/TermsAndConditionsPage/TermsAndConditionsPage';
import PrivacyPolicyPage from './Components/PrivacyPolicyPage/PrivacyPolicyPage';

/* Styles */
import './App.scss';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    const newLocationHash = location?.hash?.replace('#', '')?.trim();
    const newLocationElement = document?.getElementById(newLocationHash);
    window?.scrollTo({
      top: newLocationElement?.offsetTop - 88,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  const requiredProps = { };

  return (
    <div className='pp-app'>
      <Routes>
        <Route exact path='/' element={<Landing {...requiredProps} />} />
        <Route
          exact
          path='/faqs'
          element={<SupportPage {...requiredProps} />}
        />
        <Route
          path='/cookie-policy'
          element={<CookiePage {...requiredProps} />}
        />
        <Route element={<Landing {...requiredProps} />} />
        <Route
          exact
          path='/thank-you'
          element={<ThankyouPage {...requiredProps} />}
        />
        <Route
          exact
          path='/terms-and-conditions'
          element={<TermsAndConditionsPage {...requiredProps} />}
        />
        <Route
          exact
          path='/privacy-policy'
          element={<PrivacyPolicyPage {...requiredProps} />}
        />
      </Routes>
    </div>
  );
};

export default App;
