/* External */
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

/* Components */
import TestimonialsCustomCard from './../TestimonialsCustomCard';

/* Styles */
import './TestimonialsImageSlider.scss';

const TestimonialsImageSlider = ({ slides }) => {
  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  const carouselResponsiveOptions = {
    small: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
    big: {
      breakpoint: { max: 1439, min: 768 },
      items: 2,
    },
  };

  const carouselProps = {
    responsive: carouselResponsiveOptions,
    arrows: false,
    showDots: true,
    partialVisible: true,
    autoPlay: false,
    autoPlaySpeed: 100000,
    shouldResetAutoplay: true,
    containerClass: 'pp-testimonials-image-slider__carousel-container',
    dotListClass:
      'pp-testimonials-image-slider__carousel-container--dots-container',
  };

  return (
    <div className='pp-testimonials-image-slider'>
      <Carousel {...carouselProps}>
        {slides.map((slide, index) => {
          return <TestimonialsCustomCard data={slide} key={index} />;
        })}
      </Carousel>
    </div>
  );
};

export default TestimonialsImageSlider;
