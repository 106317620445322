/* External */
import React from 'react';

/* Styles */
import './CookieQuestions.scss';

const CookieQuestions = ({ question, answer, borderBottom, paddingBottom }) => {
  return (
    <div className='cookie-questions'>
      <h2
        className={`cookie-questions__title-question cookie-questions__title-question${
          borderBottom ? '--border-bottom' : ''
        }`}
      >
        {question}
      </h2>
      <div className='cookie-questions__right-on-big'>
        <div
          className={`cookie-questions__answer-question cookie-questions__answer-question${
            borderBottom ? '--border-bottom' : ''
          } cookie-questions__answer-question${
            paddingBottom ? '--padding-bottom' : ''
          }`}
        >
          {answer}
        </div>
      </div>
    </div>
  );
};

export default CookieQuestions;
