/* External */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

/* Components */
import CookieIntroCP from './../CookieIntroCP/CookieIntroCP';
import CookieQuestionContainer from './../CookieQuestionContainer/CookieQuestionContainer';
import CookieTypes from '../CookieTypes/CookieTypes';
import CookieList from './../CookieList/CookieList';
import Footer from './../Footer/Footer';

/* Styles */
import './CookiePage.scss';

/* Other */
import {
  COOKIE_POLICY_QUESTIONS,
  COOKIE_POLICY_TYPES,
  COOKIE_DURATION,
  COOKIE_POLICY_LIST,
} from './../../Utils/Constants/Data';
import { SCREEN_SIZE_VERY_BIG } from './../../Utils/Constants/Breakpoints';
import {
  useNavbar,
  useGetCurrentBreakpoint,
} from './../../Utils/Functions/Hooks';

const CookiePage = ({}) => {
  const currentBreakpoint = useGetCurrentBreakpoint(useState, useEffect);
  const currentNavBar = useNavbar(useState, currentBreakpoint);

  return (
    <div
      className={`pp-cookie-page ${
        currentNavBar ? 'pp-cookie-page--nav-bar-open' : ''
      }`}
    >
      <Helmet>
        <title>BeHealthy Rewards Program | BeHealthy By Globant X</title>
      </Helmet>
      {currentNavBar}
      <div
        id={'CookieIntroCP'}
        className={`pp-cookie-page__section${
          currentBreakpoint === SCREEN_SIZE_VERY_BIG ? '--grey' : ''
        }`}
      >
        <span className='pp-cookie-page__section--wrapper'>
          <CookieIntroCP />
        </span>
      </div>
      <div
        id={'CookieQuestion'}
        className={`pp-cookie-page__section${
          currentBreakpoint !== SCREEN_SIZE_VERY_BIG ? '--grey' : ''
        }`}
        style={{ padding: 0 }}
      >
        <span className='pp-cookie-page__section--wrapper'>
          <CookieQuestionContainer
            cookieQuestions={COOKIE_POLICY_QUESTIONS}
            cookieTypes={COOKIE_POLICY_TYPES}
            cookieDuration={COOKIE_DURATION}
          />
        </span>
      </div>
      <div
        id={'CookieTypes'}
        className='pp-cookie-page__section pp-cookie-page__section--green'
      >
        <span className='pp-cookie-page__section--wrapper'>
          <CookieTypes />
        </span>
      </div>
      <div
        id={'Footer'}
        className={
          'pp-cookie-page__section pp-support-page__section--black pp-cookie-page__section--padding-sides-footer'
        }
      >
        <span className='pp-cookie-page__section--wrapper'>
          <Footer />
        </span>
      </div>
    </div>
  );
};
export default CookiePage;
