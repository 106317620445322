/* External */
import React from 'react';

/* Components */
import HowItWorksCustomCard from './../HowItWorksCustomCard';

/* Styles */
import './HowItWorks.scss';

/* Others */
import { HOW_IT_WORKS } from './../../Utils/Constants/Data';

const HowItWorks = () => {
  const listedItem = HOW_IT_WORKS.map((values, index) => (
    <HowItWorksCustomCard
      key={index}
      text={values.text}
      src={values.src}
      title={values.title}
      alt={values.title}
      customClass={'pp-how-it-works__workflow--item'}
    />
  ));

  return (
    <div className='pp-how-it-works'>
      <span className='pp-how-it-works__title'>
        A highly configurable program that is <br></br> simple and direct
      </span>
      <div className='pp-how-it-works__subtitle'>
        BeHealthy leverages hyper-personalized goals and and gamified algorithms to recognize, encourage and reward physical activity. With proven results. 
      </div>
      <div className='pp-how-it-works__workflow'>{listedItem}</div>
      <div className='pp-how-it-works__subtitle last'>
        Rewards are determined by sponsor and can be anything from points in an existing loyalty program to payments toward a new Apple Watch. 
      </div>
    </div>
  );
};

export default HowItWorks;
