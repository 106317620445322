import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
/* Styles */
import './PrivacyPolicyPage.scss';
/* Components */
import Footer from '../Footer';
/* Others */
import { TERMS_AND_CONDITIONS } from '../../Utils/Constants/Data';
import {
useNavbar,
useGetCurrentBreakpoint,
} from '../../Utils/Functions/Hooks';
const PrivacyPolicyPage = () => {
const currentBreakpoint = useGetCurrentBreakpoint(useState, useEffect);
const currentNavBar = useNavbar(useState, currentBreakpoint);
return (
<div className='pp-privacy-policy'>
   <Helmet>
      <title>BeHealthy Rewards Program | BeHealthy By Globant X</title>
   </Helmet>
   {currentNavBar}
   <div className='pp-privacy-policy__main-section'>
      <h2 className='pp-privacy-policy__main-section-title'>
         Privacy Policy
      </h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
         <b>Effective Date: </b> November 15th, 2023
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         Globant values individual privacy and we want to give our application users the opportunity 
         to know what information we collect about them and how it is treated by us. 
         If you are a California resident, please review our 
         {' '}<a className='pp-terms-and-conditions__main-section-paragraph--link'
            href='#california_privacy_rights'
            target='_blank'> 
            California Notice at Collection and Privacy Rights
         </a>   
         .
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         This Privacy Policy explains:
      <ul className='pp-privacy-policy__main-section-list first'>
         <li>1. Scope.</li>
         <li>2. Personal data we collect.</li>
         <li>3. Purposes of using your personal information and legal basis.</li>
         <li>4. Retention of your personal information.</li>
         <li>5. Disclosures of personal information.</li>
         <li>6. Cookies and Other Tracking Mechanisms.</li>
         <li>7. Security.</li>
         <li>8. Third-Party Accounts.</li>
         <li>9. Where is your personal information stored and which are your rights?.</li>
         <li>10. Your Privacy Choices.</li>
         <li>11. External Links.</li>
         <li>12. Personal data of minors.</li>
         <li>13. California Notice at Collection and Privacy Rights.</li>
         <li>14. Contact us.</li>
      </ul>
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         INITIAL EXPLANATORY NOTE 
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         Capitalized terms not otherwise defined in this Privacy Policy shall have the meaning 
         ascribed to such terms in the Terms and Conditions. You should read this Privacy Policy 
         together with our Terms and Conditions in order to better understand the rules governing 
         the use of the application Globant BeHealthy (our “Application” and “Services”). 
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         We may update this Privacy Policy from time to time. When we update the Privacy Policy, 
         we will revise the “Effective Date” date above and post the new Privacy Policy. 
      </p>

      <h2 class="pp-privacy-policy__main-section-subtitle">1. Scope.</h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
         This privacy policy (“Policy”) explains how we process personal data from customers 
         and visitors to our websites (“Sites”) and users of our Application or Services as a 
         controller or business under applicable privacy laws.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         When we collect personal data as a processor on behalf of business customers, 
         we process personal data in accordance with our contract with them and their privacy policy 
         will apply, instead of this one.
      </p>

      <h2 class="pp-privacy-policy__main-section-subtitle">2. Personal data we collect.</h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
         While the personal data we collect varies depending upon your use of our Services, 
         or Sites and our interactions with you, we may collect personal data from you, from third-party sources, 
         and automatically in the following circumstances
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         <b>Personal data collected directly from you.</b> We may collect the following personal data from you:
         <ul className='pp-privacy-policy__main-section-list'>
            <li>a) If you use the Application and create an account, Account Services. 
            When you register or create an account, we may collect your name, email address, 
            timezone, date of birth, sex.</li>
            <li>b) Communications. We collect the information you provide such as contact information 
            and records of our communications.</li>
            <li>c) Surveys. When you fill out a survey, we collect your response, which depends on the type 
            of survey, but may include contact information and your satisfaction with the Application, 
            the Site, or our Services.</li>
            <li>d) Other Information. We may collect other personal data that you consent to or would 
            reasonably expect based on the nature of the circumstances.</li>
            <li>e) Customer Service and Support. When you contact us for support, to request a demo or 
            other customer service requests, we maintain support tickets and other records of your requests / 
            related communications. For example, when you use our contact us form, we may collect details 
            including your name, country, email address, and the nature of your request. We may also collect 
            your company, job title, and industry if you choose to provide it.</li>
         </ul>
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         <b>Personal Data Collected from Third Parties.</b> If you are using our Application, 
         we may collect personal data about you from third party sources such as public databases, 
         and data from your HealthKit or Health Connect. This data includes: Weight, Height, Exercise time, 
         Calories, Stand time, Steps, Workouts.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         <b>Personal Data Collected Automatically.</b> When you visit our Site or use our Application or Services, 
         we and third parties may collect personal data including through the use of cookies, pixel tags, 
         and other similar technologies such as:
         <ul className='pp-privacy-policy__main-section-list'>
            <li>Device and Browsing Information. When you visit our Site, we may collect information, 
            which may be considered personal data under certain laws, such as IP address, browser type, 
            domain names, access times, date/time stamps, operating system, language, device type, 
            unique or online identifier, Internet service provider, referring and exiting URLs, clickstream data, 
            and similar device and browsing information.</li>
            <li>Activities and Usage. We also collect activity information related to your use of the Site, 
            such as information about links clicked, searches conducted, features used, items viewed, 
            time spent on certain pages, your interactions with us, log file information, and other activity 
            and usage information.</li>
            <li>Location Information. We may also collect or derive general location information about you, 
            such as through your IP address.</li>
         </ul>
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         When you are using our Application we may collect data from your mobile device. We may require features 
         that rely on the access and use of additional information acquired by your mobile device or require access 
         to certain services on your mobile device that will enhance your experience with our Products 
         but are not required to use the Services. Before we access this information or these features from your 
         mobile device, we will ask for your permission. If you provide such permission, we will collect the 
         information for the specific purposes explained at the time we ask for your permission.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         For more information please review Section 6 Cookies and Other Tracking Mechanism and our cookie policy page.
      </p>

      <h2 class="pp-privacy-policy__main-section-subtitle">3. Purposes of using your personal information and legal basis.</h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
         Generally, we use personal data for the following purposes:
         <ul className='pp-privacy-policy__main-section-list'>
            <li>Providing Services and Support. To provide and operate our Site or Application, 
            allow you access to the Site and Application, communicate with you about your use of the 
            Site and Application, provide troubleshooting and technical support, respond to your inquiries, 
            fulfill your orders and requests, communicate with you, and for similar service and support purposes.</li>
            <li>Communications. To respond to your questions and fulfill your orders and requests; to send you 
            communications, which you have requested or that may interest you; and to notify you of changes to our 
            Application.</li>
            <li>Analytics and Improvement. To better understand how users access and use the Site and Application, 
            and for other research and analytical purposes, such as to evaluate and improve our Site and Application 
            and business operations, to develop our Site and Application and features, and for internal quality 
            control and training purposes.</li>
            <li>Planning and Managing Events. For event planning and management, including registration, attendance, 
            connecting you with other event attendees, and contacting you about relevant events and the application.</li>
            <li>Research and Surveys. To administer surveys and questionnaires, such as for market research or 
            user satisfaction purposes.</li>
            <li>Security and Protection of Rights. To protect the Site and Application and our business operations; 
            to prevent and detect fraud, unauthorized activities and access, and other misuse; where we believe 
            necessary to investigate, prevent or take action regarding illegal activities, suspected fraud, 
            situations involving potential threats to the safety or legal rights of any person or third party, 
            or violations of our terms or this Policy. </li>
            <li>Legal Proceedings and Obligations. To comply with the law and our legal obligations, to respond to 
            legal processes and related to legal proceedings.</li>
            <li>General Business and Operational Support. Related to the administration of our general business, 
            accounting, auditing, compliance, recordkeeping, and legal functions.</li>
         </ul>
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         Globant will also generate reports with the following Application generated data:
         <ul className='pp-privacy-policy__main-section-list'>
         <li>List of Users</li>
         <li>Monthly Goals achieved (based on number of stars)</li>
         <li>Program population level information (meaning non-personally identified information) 
         illustrating program engagement and accomplishments. </li>
         </ul>
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         We may use and disclose aggregate, deidentified, and other non-identifiable data related to our business 
         and the Services for quality control, analytics, research, development and other purposes. Where we use, 
         disclose, or process deidentified data we will maintain and use the information in deidentified form and not 
         to attempt to reidentify it except where permitted by law.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         The use of information received by the Application from Health Connect will adhere to the Health Connect Permissions 
         policy, including the Limited Use requirements.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         We are entitled to use your personal information based on (i) our legitimate interest such as for research 
         and development, to market and promote our services, to protect our legal rights and interests, to the 
         extent that your interests or your fundamental rights are not overridden; (ii) your consent to do so for 
         a specific purpose; (iii) to process your data to comply with a legal obligation.
      </p>

      <h2 class="pp-privacy-policy__main-section-subtitle">4. Retention of your personal information.</h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
         Globant will retain your personal information for as long as necessary to fulfill the purposes for which 
         it was collected or as necessary to comply with our legal obligations, resolve disputes, and maintain 
         appropriate business records. If you request we delete your personal data from our databases, please note
         we may still retain your personal data as necessary to comply with our legal obligations, regulatory requests, 
         resolve disputes, and enforce our agreements.
      </p>

      <h2 class="pp-privacy-policy__main-section-subtitle">5. Disclosures of personal and sensitive information.</h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
         In general, we disclose and make available personal data to third parties to provide or improve the Site’s 
         and Application’s use case or features in the following ways:
         <ul className='pp-privacy-policy__main-section-list'>
            <li>Corporate Affiliates. We may disclose personal data to corporate affiliates in order to provide our 
            Site and Services.</li>
            <li>Processors and Service Providers. We may disclose personal data to our service providers who perform 
            services on our behalf such as IT service providers, hosting providers, email service providers, 
            chat bot providers, etc.</li>
            <li>Third- Parties, Platforms, and Services. We may disclose or make available personal data to 
            third-party platforms and providers that we use to provide or make available certain features or portions 
            of the Site or Application, or as necessary to respond to your requests.</li>
            <li>Third-Party Analytics Providers. We may also engage third-party analytics companies to collect 
            information about how you access and use our Site, Application and Services, to improve our Site 
            and Application.</li>
            <li>Compliance, governance and legal requirements. We may disclose personal data to comply with legal 
            and compliance obligations and to respond to legal process and related to legal proceedings. 
            For example, we may disclose personal data in response to subpoenas, court orders, and other lawful 
            requests by regulators and law enforcement. We may also disclose information, including personal data, 
            related to litigation and other legal claims or proceedings, for our internal accounting, auditing, 
            compliance, recordkeeping, and legal functions. In addition, we may disclose the names of sweepstakes 
            and contests winners, in accordance with applicable law. </li>
            <li>Business Transfers. We may disclose personal data as part of commercial transactions 
            (e.g., mergers, acquisitions, bankruptcy, or other similar business transactions) and in contemplation 
            of such transactions (e.g., due diligence).</li>
            <li>If you contact us regarding identity theft or some other form of unauthorized use of the Application 
            and Services, Globant may disclose pertinent information to law enforcement agencies and other third 
            parties with responsibility and authority.</li>
            <li>Other Disclosures. We may disclose your personal data for other purposes, which we will notify you 
            of and/or obtain your consent when required.</li>
         </ul>
      </p>

      <h2 class="pp-privacy-policy__main-section-subtitle">6. Cookies and Other Tracking Mechanisms</h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
         We and other third parties use cookies, pixel tags, and other similar tracking mechanisms to 
         automatically collect information about browsing activity, device type, and similar information 
         within our Site and Services. We use this information to, for example, analyze and understand how you access, 
         use and interact with our Site and Services; to identify and resolve bugs and errors in our Site and Services; 
         to assess, secure, protect, optimize, and improve the performance of our Site and Services; for marketing, 
         advertising, measurement and analytics purposes; and to personalize content on our Site and Services. 
         We may also deidentify or aggregate such information to analyze trends, administer our Site and Services, 
         gather broad demographic information for aggregate uses and for any other lawful purpose.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         <b>Cookies.</b> “Cookies” are alphanumeric identifiers used for tracking purposes. Some cookies allow us 
         to make it easier for you to navigate, while others are used to enable a faster log-in process, support 
         the security and performance of the Site, or allow us to track activity and usage data within the Site 
         and across websites.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         <b>Pixel Tags and Similar Technologies.</b> Pixel tags (sometimes called web beacons or clear GIFs) are 
         tiny graphics with a unique identifier, similar in function to cookies. We may use these tracking 
         technologies to understand users’ activities, help manage content and compile usage statistics, 
         and in emails to let us know when they have been opened or forwarded so we can track response rates and gauge 
         the effectiveness of our communications.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         <b>Third Party Analytics.</b> We use third party tools, such as Google Analytics, which are operated by 
         third party companies to evaluate usage of our Site. These third-party analytics companies use cookies, 
         pixels, and other tracking technologies to collect usage data about our Site to provide us with reports 
         and metrics that help us evaluate usage of our Site, improve our Site, and enhance performance and user 
         experience. To learn more about Google’s privacy practices, please review the Google Privacy Policy at 
         {' '}<a className='pp-terms-and-conditions__main-section-paragraph--link'
            href='https://www.google.com/policies/privacy/partners/'
            target='_blank'> 
            https://www.google.com/policies/privacy/partners/
         </a> 
         . You can also download the Google Analytics Opt-out Browser 
         Add-on to prevent your data from being used by Google Analytics at 
         {' '}<a className='pp-terms-and-conditions__main-section-paragraph--link'
            href='https://tools.google.com/dlpage/gaoptout'
            target='_blank'> 
           https://tools.google.com/dlpage/gaoptout
         </a>.
      </p>

      <h2 class="pp-privacy-policy__main-section-subtitle">7. Security.</h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
         No data transmission over the Internet can be guaranteed to be 100% secure. As a result, while we strive to 
         protect your personal data, we cannot guarantee or warrant the security of any information collected through our 
         Site or Application. You use our Site and Application and provide us with your personal data at your own risk.
      </p>

      <h2 class="pp-privacy-policy__main-section-subtitle">8. Third-Party Accounts.</h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
         Globant allows you to sign up and log in to the Application using accounts you create with third-party 
         products and services, such as Apple, Google or FitBit (collectively, “Third-Party Accounts”). 
         If you access the Application with Third-Party Accounts we will collect information that you have agreed 
         to make available such as your name, email address, profile information and preferences. 
         This information is collected by the Third-Party Account provider and is provided to Globant under 
         their privacy policies. You can generally control the information that we receive from these sources 
         using the privacy controls in your Third-Party Account.
      </p>

      <h2 class="pp-privacy-policy__main-section-subtitle">9. Where is your personal and sensitive information stored and which are your rights?</h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
         Globant values the information that you choose to provide and will take reasonable steps to protect 
         your personal information from loss, misuse or unauthorized alteration. 
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         <b>Storage of the Information.</b> All of the information collected by Globant through the application 
         and services is stored on secured servers, to protect against unauthorized use and access. 
         Our servers are located in the United States of America. 
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         Irrespective of which country you live in, you authorize us to transfer, store, and use your information 
         in the United States. In some of these countries, the privacy and data protection laws and rules 
         regarding when government authorities may access data may vary from those in the country where you live. 
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         <b>Your rights.</b> You may exercise any of your rights over your personal data as per applicable 
         regulations, that may include access, rectification, deletion, among others, directly by email to the 
         following address: 
         {' '}<a className='pp-terms-and-conditions__main-section-paragraph--link'
            href='mailto:behealthy-privacy@globant.com'
            target='_blank'> 
            behealthy-privacy@globant.com
         </a> 
         . You can delete your account, along with your personal data at any time by entering the option 
         Preferences and Delete account in the mobile app.
      </p>

      <h2 class="pp-privacy-policy__main-section-subtitle">10. Your Privacy Choices.</h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
         <b>Marketing Communications.</b> We may send periodic promotional emails or other similar communications to you. 
         You may opt-out of these communications by following the instructions provided to you in the communication, 
         such as using the “unsubscribe” option.   If you opt-out of receiving promotional content from us, 
         we may still send you communications about your account or any Services you have requested or received from us.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         <b>Industry Ad Choice Programs.</b> You can also control how participating third-party ad companies use 
         the information that they collect about your visits to our Site and those of third parties, 
         in order to display more relevant targeted advertising to you. If you are in the U.S., 
         you can obtain more information and opt-out of receiving targeted ads from participating 
         third-party ad networks at aboutads.info/choices (Digital Advertising Alliance). You may also download 
         the DAA AppChoices tool in order to help control interest-based advertising on apps on your mobile device.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         <b>Cookie Settings.</b> You can adjust your cookie settings by clicking “Cookie Preferences” in the footer 
         of our website. Visitors to our Site who disable cookies will be able to browse the Site, but some features 
         may not function. If you come to our Site from a different device or from a different browser on the 
         same device, you will need to apply your cookie settings for that browser and/or device as well.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
         <b>Browser Signals.</b> To the extent required by applicable law, if our Site detects that your browser 
         is transmitting an opt-out preference signal, such as a “global privacy control” (or GPC) signal, 
         we will apply that signal to opt that particular browser on your device out of targeting cookies on our Site. 
         If you come to our Site from a different device or from a different browser on the same device, 
         you will need to apply GPC for that browser and/or device as well. We do not respond to do not track signals.
      </p>

      <h2 class="pp-privacy-policy__main-section-subtitle">11. External Links.</h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
         Our Sites and Services may contain links to third-party websites or features. Any access to and use of 
         such linked websites or features is not governed by this Policy, but instead is governed by the privacy 
         policies of those third parties. We are not responsible for the information practices of such third parties, 
         including their collection of your personal data. You should review the privacy policies and terms for 
         any third-party websites before proceeding to those websites or using those features.
      </p>

      <h2 class="pp-privacy-policy__main-section-subtitle">12. Personal data of minors.</h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
         The Site or Application is not designed for minors, and we do not knowingly collect personal data from minors.
      </p>

      <h2 id="california_privacy_rights" class="pp-privacy-policy__main-section-subtitle">13. California Notice at Collection and Privacy Rights.</h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
        This section of the Policy (the “California Privacy Notice”) provides additional information for California 
        residents (“you”) and describes our information practices pursuant to applicable California privacy laws, 
        including the California Consumer Privacy Act and the regulations issued thereto, each as amended (the “CCPA”).
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        In this section, “Personal Information” means information that identifies, relates to, describes, is reasonably 
        capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular 
        California resident or household. This section does not address or apply to our handling of publicly available 
        information or Personal Information that is otherwise exempt under the CCPA.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <b>Categories of Personal Information Collected and Disclosed.</b> The following table identifies the categories 
        of Personal Information we may collect about you (and may have collected in the prior 12 months), as defined 
        by the CCPA, as well as the categories of third parties to whom we may disclose this information for a 
        business or commercial purpose. Depending on how you use the Services, we may collect and disclose the following 
        categories of personal information. For more information, please see <b>Section 1. Personal data we collect</b> and <b>Section 2. Purposes of using your personal information and legal basis</b> above.
      </p>

      <p className='pp-privacy-policy__main-section-paragraph'>
         <div className='pp-privacy-policy__table-section'>
            <table border="1">
             <tr>
               <th colspan="2"><b>Personal Information Collected</b></th>
               <th rowspan="2"><b>Third Party Disclosures for Business or Commercial Purposes</b></th>
             </tr>
             <tr>
               <th><b><i>Categories</i></b></th>
               <th><b><i>Description</i></b></th>
             </tr>
             <tr>
               <td>Identifiers</td>
               <td>Includes name, email address, unique personal identifier, online identifier, Internet Protocol (IP) address, or similar information.</td>
               <td>
                 <ul className='pp-privacy-policy__main-section-list'>
                   <li>government entities and law enforcement</li>
                   <li>affiliates and subsidiaries</li>
                   <li>operating systems and platforms</li>
                   <li>others as permitted or required by law</li>
                 </ul>
               </td>
             </tr>
             <tr>
               <td>Categories of Personal Information Described in Cal. Civ. Code § 1798.80</td>
               <td>Includes records containing Personal Information, such as name, product reviews.</td>
               <td>
                 <ul className='pp-privacy-policy__main-section-list'>
                   <li>government entities and law enforcement</li>
                   <li>affiliates and subsidiaries</li>
                   <li>operating systems and platforms</li>
                   <li>others as permitted or required by law</li>
                 </ul>
               </td>
             </tr>
             <tr>
               <td>Internet or Other Electronic Network Activity Information</td>
               <td>Includes information regarding interaction with our application, including access logs and other activity information related to the use of our application</td>
               <td>
                 <ul className='pp-privacy-policy__main-section-list'>
                   <li>government entities and law enforcement</li>
                   <li>affiliates and subsidiaries</li>
                   <li>operating systems and platforms</li>
                   <li>others as permitted or required by law</li>
                 </ul>
               </td>
             </tr>
             <tr>
               <td>Timezone Data</td>
               <td>Includes data regarding your Timezone.</td>
               <td>
                 <ul className='pp-privacy-policy__main-section-list'>
                   <li>government entities and law enforcement</li>
                   <li>affiliates and subsidiaries</li>
                   <li>operating systems and platforms</li>
                   <li>others as permitted or required by law</li>
                 </ul>
               </td>
             </tr>
             <tr>
               <td>Audio, Electronic, Visual, or Similar Information</td>
               <td>Includes audio, electronic, visual, or similar information such as, photographs and images (e.g., that you provide us)</td>
               <td>
                 <ul className='pp-privacy-policy__main-section-list'>
                   <li>government entities and law enforcement</li>
                   <li>affiliates and subsidiaries</li>
                   <li>operating systems and platforms</li>
                   <li>others as permitted or required by law</li>
                 </ul>
               </td>
             </tr>
             <tr>
               <td>Characteristics of Protected Classifications</td>
               <td>Includes characteristics of protected classifications under California and federal laws such as date of birth and sex, and health related information.</td>
               <td>
                 <ul className='pp-privacy-policy__main-section-list'>
                   <li>government entities and law enforcement</li>
                   <li>affiliates and subsidiaries</li>
                   <li>operating systems and platforms</li>
                   <li>others as permitted or required by law</li>
                 </ul>
               </td>
             </tr>
             <tr>
               <td>Profiles and Inferences</td>
               <td>Includes inferences drawn from other Personal Information that we collect to create a profile reflecting an individual’s preferences and purchase tendencies.</td>
               <td>
                 <ul className='pp-privacy-policy__main-section-list'>
                   <li>government entities and law enforcement</li>
                   <li>affiliates and subsidiaries</li>
                   <li>operating systems and platforms</li>
                   <li>others as permitted or required by law</li>
                 </ul>
               </td>
             </tr>
             <tr>
               <td>Sensitive Personal Information</td>
               <td>Includes age, gender and health related information.</td>
               <td>
                 <ul className='pp-privacy-policy__main-section-list'>
                   <li>government entities and law enforcement</li>
                   <li>affiliates and subsidiaries</li>
                   <li>operating systems and platforms</li>
                   <li>others as permitted or required by law</li>
                 </ul>
               </td>
             </tr>
           </table>
         </div>
      </p>

      <p className='pp-privacy-policy__main-section-paragraph'>
        <b>Sale and Sharing of Personal Information.</b> Additionally, the CCPA defines "sale" as disclosing or 
        making available to a third-party Personal Information in exchange for monetary or other valuable consideration, 
        and “sharing” includes disclosing or making available Personal Information to a third party for purposes of 
        cross-contextual behavioral advertising. 
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        We do not sell or share any Personal Information about you.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <b>Sources of Personal Information.</b> As further described in <b>Section 1: Personal data we collect</b>, 
        we collect Personal Information from the following sources: directly from you; operating systems and platforms; 
        affiliated entities; and government entities.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <b>Retention.</b> We retain the Personal Information we collect only as reasonably necessary for the purposes 
        described above or otherwise disclosed to you at the time of collection. We retain Personal Information as 
        necessary to comply with our tax, accounting and recordkeeping obligations, to provide you with the services 
        you have requested, as well as an additional period of time as necessary to protect, defend or establish our 
        rights, defend against potential claims, and comply with our legal obligations. In some cases, rather than 
        delete your Personal Information, we may deidentify or aggregate it and use it in compliance with the CCPA.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <b>Purposes for Collecting, Using, Disclosing, and Processing Personal Information.</b> As more fully described 
        in the <b>Section 2 Purposes of using your personal information and legal basis</b>, we collect, use, and 
        otherwise process the above categories of Personal Information to provide our services and grant you access 
        to the application, respond to and fulfill your requests, as otherwise directed or consented to by you, and 
        for the following business and commercial purposes: to provide services and support, communications, analytics 
         and improvement, customization and personalization, events, research and studies, security and the protection 
      of rights, legal proceedings and obligations, and general business and operational support.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <b>Sensitive Personal Information.</b> We do not use or disclose of Sensitive Personal Information beyond the 
        purposes authorized by the CCPA.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <b>California Residents Rights.</b> The CCPA provides you with certain rights regarding your Personal Information.
         Please note that these rights are subject to certain conditions and exceptions.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <i>Right to Know/Request Access.</i> With respect to the Personal Information we have collected about you in the 
        prior twelve (12) months, you have the right to know:
        <ul className='pp-privacy-policy__main-section-list'>
          <li>The categories of Personal Information we collected about you;</li>
          <li>The categories of sources from which we collected your Personal Information;</li>
          <li>The business or commercial purposes for collecting, selling, or sharing your Personal Information;</li>
          <li>The categories of third parties to whom we have disclosed your Personal Information; and</li>
          <li>The specific pieces of your Personal Information we have collected.</li>
        </ul>
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <i>Right to Delete.</i> You have the right to request we delete your Personal Information.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <i>Right to Correct.</i> You have the right to request that we correct inaccuracies in your Personal Information.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <i>Right to Opt-Out of Sales and Sharing.</i> As we do not “sell” or “share” your Personal Information, 
        this option does not apply. 
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <i>Right to Limit Use of Sensitive Personal Information.</i> We do not engage in uses or disclosures of 
        “Sensitive Personal Information” that would trigger the right to limit use of Sensitive Personal Information 
        under the CCPA.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <i>Right to Non-Discrimination.</i> We will not discriminate against you for exercising any of the rights 
        described in this section.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <b>Exercising Your Rights.</b> If you are a California resident and would like to exercise your CCPA rights, 
        you may do so via any of the methods described below:
        <ul className='pp-privacy-policy__main-section-list'>
          <li>Calling us at 1-800-921-2240</li>
          <li>Emailing us at 
          {' '}<a className='pp-terms-and-conditions__main-section-paragraph--link'
            href='mailto:behealthy-privacy@globant.com'
            target='_blank'> 
            behealthy-privacy@globant.com
         </a> </li>
        </ul>
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <b>Verification.</b> Before responding to your request, we must first verify your identity using the Personal 
        Information you recently provided to us. We will take steps to verify your request by matching the information 
        provided by you with the information we have in our records. In some cases, we may request additional information 
        in order to verify your identity, or where necessary to process your request. If we are unable to verify your 
        identity after a good faith attempt, we may deny the request and, if so, will explain the basis for the denial.
      </p>
      <p className='pp-privacy-policy__main-section-paragraph'>
        <b>Authorized Agents.</b> You may designate someone as an authorized agent to submit requests and to act on your 
        behalf. Authorized agents will be required to provide proof of their authorization and we may also require that 
        the relevant consumer directly verify the identity and the authority of the authorized agent.
      </p>

      <h2 class="pp-privacy-policy__main-section-subtitle">14. Contact us.</h2>
      <p className='pp-privacy-policy__main-section-paragraph'>
         If you have any questions regarding this Policy, please contact us at: 
         {' '}<a className='pp-terms-and-conditions__main-section-paragraph--link'
            href='mailto:behealthy-privacy@globant.com'
            target='_blank'> 
            behealthy-privacy@globant.com
         </a>
      </p>
   </div>
   <div className='pp-privacy-policy__footer-section'>
      <Footer />
   </div>
</div>
);
};
export default PrivacyPolicyPage;