/* External */
import React, { useState, useEffect } from 'react';

/* Styles */
import './WhyBeHealthy.scss';

/* Others */
import {
  useGetCurrentBreakpoint,
  useResponsiveImageSource,
} from '../../Utils/Functions/Hooks';

const OurProgram = () => {
  const currentBreakpoint = useGetCurrentBreakpoint(useState, useEffect);
  const welcomeBackResponsiveImageSource = useResponsiveImageSource(
    currentBreakpoint,
    'why_program'
  );

  return (
    <div className='pp-why-BeHealthy'>
      <div className='pp-why-BeHealthy__first-section-container'>
        <h2 className='pp-why-BeHealthy__title'>
        The BeHealthy program is <span>simple & direct:</span>
        </h2>
        <p className='pp-why-BeHealthy__introduction'>
          Globant has partnered with <strong>Apple</strong> to build an
          innovative program that incentivizes participants to meet monthly
          physical activity goals and{' '}
          <strong>foster stronger relationships with sponsoring brands.</strong>
        </p>
      </div>
      <span className='pp-why-BeHealthy__second-section-container'>
        <span className='pp-why-BeHealthy__second-section-container--half-with-content-to-right'>
          <img
            className='pp-why-BeHealthy__image'
            src={welcomeBackResponsiveImageSource}
            alt='Welcome Back'
          />
        </span>
        <span className='pp-why-BeHealthy__second-section-container--half-with-content-to-left'>
          <ul className='pp-why-BeHealthy__list'>
            <li className='pp-why-BeHealthy__list--item'>
              <img
                className='pp-why-BeHealthy__list--item-icon'
                src='/Assets/Images/why_program_firstIcon.png'
                alt='Strengthen brand loyalty'
              />
              Strengthen brand loyalty
            </li>
            <li className='pp-why-BeHealthy__list--item'>
              <img
                className='pp-why-BeHealthy__list--item-icon'
                src='/Assets/Images/why_program_secondIcon.png'
                alt='Increase customer retention and stickiness'
              />
              Increase customer retention and stickiness
            </li>
            <li className='pp-why-BeHealthy__list--item'>
              <img
                className='pp-why-BeHealthy__list--item-icon'
                src='/Assets/Images/why_program_thirdIcon.png'
                alt='Engage with customers as they track progress'
              />
              Engage with customers as they track progress
            </li>
            <li className='pp-why-BeHealthy__list--item'>
              <img
                className='pp-why-BeHealthy__list--item-icon'
                src='/Assets/Images/why_program_fourthIcon.png'
                alt='Build a positive & proactive communication channel'
              />
              Build a positive & proactive communication channel
            </li>
          </ul>
        </span>
      </span>
    </div>
  );
};

export default OurProgram;
