/* External */
import React, { useState, useEffect } from 'react';

/* Components */
import CustomButton from './../CustomButton';

/* Styles */
import './HeroBanner.scss';

/* Others */
import {
  useGetCurrentBreakpoint,
  useResponsiveImageSource,
} from './../../Utils/Functions/Hooks';

const HeroBanner = ({ }) => {
  const currentBreakpoint = useGetCurrentBreakpoint(useState, useEffect);
  const heroBannerResponsiveImageSource = useResponsiveImageSource(
    currentBreakpoint,
    'hero-banner'
  );

  const textSection = (
    <div className='pp-hero-banner__left_on_big'>
      <h2 className='pp-hero-banner__title'>
        There’s no business growth without customer and employee wellness
      </h2>
      <p className='pp-hero-banner__subtitle'>
        The BeHealthy rewards program encourages healthy customer and
        employee activity while strengthening brand loyalty and amplifying
        engagement.
      </p>
      <CustomButton
        text='Request a demo'
        filled={true}
        href={encodeURI(window.location.search).includes('utm') 
        ? encodeURI(window.location.search) + '#ContactForm'
        : '/#ContactForm' }
        isNavigation={true}
      />
    </div>
  );

  const imageSection = (
    <div className='pp-hero-banner__right_on_big'>
      <img src={heroBannerResponsiveImageSource} alt='Be Healthy Program' />
      <CustomButton
        text='Request a demo'
        filled={true}
        href={encodeURI(window.location.search).includes('utm') 
        ? encodeURI(window.location.search) + '#ContactForm'
        : '/#ContactForm' }
        isNavigation={true}
      />
    </div>
  );

  let orderedSections = [textSection, imageSection];

  return (
    <div className='pp-hero-banner'>
      {orderedSections[0]}
      {orderedSections[1]}
    </div>
  );
};

export default HeroBanner;
