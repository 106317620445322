/* External */
import React from 'react';

/* Styles */
import './Contact.scss';

const Contact = () => {
  return (
    <div className='pp-contact'>
      <div className='pp-contact__left'>
        <span className='pp-contact__title'>Contact us</span>
        <div className='pp-contact__subtitle'>Globant BeHealthy Support</div>
      </div>
      <div className='pp-contact__right'>
        <p className='pp-contact__main-text'>
          For any technical issues or bugs raise a ticket at Invgate  - Globant Applications --> Mobile Apps -> BeHealthy
        </p>
        <p className='pp-contact__main-text'>
          Any other questions about the program, please contact <a className='pp-contact__email' href='mailto:behealthy@globant.com'>behealthy@globant.com</a>
        </p>
      </div>
    </div>
  );
};

export default Contact;
