/* External */
import React, { useState, useEffect } from 'react';

/* Components */
import TestimonialsImageSlider from './../TestimonialsImageSlider';
import TestimonialsCustomCard from './../TestimonialsCustomCard';

/* Styles */
import './Testimonials.scss';

/* Others */
import { useGetCurrentBreakpoint } from './../../Utils/Functions/Hooks';
import { SLIDER_DATA_TESTIMONIAL } from './../../Utils/Constants/Data';
import {
  SCREEN_SIZE_VERY_SMALL,
  SCREEN_SIZE_SMALL,
  SCREEN_SIZE_MEDIUM_SMALL,
  SCREEN_SIZE_MEDIUM,
  SCREEN_SIZE_MEDIUM_BIG,
  SCREEN_SIZE_BIG,
  SCREEN_SIZE_VERY_BIG,
} from './../../Utils/Constants/Breakpoints';

const Testimonials = () => {
  const currentBreakpoint = useGetCurrentBreakpoint(useState, useEffect);
  let currentWorkflow;

  switch (currentBreakpoint) {
    case SCREEN_SIZE_VERY_BIG:
    case SCREEN_SIZE_BIG:
      currentWorkflow = (
        <>
          {SLIDER_DATA_TESTIMONIAL.map((dataEntry, index) => (
            <TestimonialsCustomCard data={dataEntry} key={index} />
          ))}
        </>
      );
      break;

    case SCREEN_SIZE_MEDIUM_BIG:
    case SCREEN_SIZE_MEDIUM:
    case SCREEN_SIZE_MEDIUM_SMALL:
    case SCREEN_SIZE_SMALL:
    case SCREEN_SIZE_VERY_SMALL:
    default:
      currentWorkflow = (
        <TestimonialsImageSlider slides={SLIDER_DATA_TESTIMONIAL} />
      );
      break;
  }

  return (
    <div className='pp-testimonials'>
      <span className='pp-testimonials__title'>
        Positive reviews speak to stronger loyalty
      </span>
      <div className='pp-testimonials__subtitle'>
        Participants recognize the positive impact of being part of the program.
      </div>
      <div className='pp-testimonials__workflow'>{currentWorkflow}</div>
    </div>
  );
};

export default Testimonials;
