/* External */
import React from 'react';

/* Styles */
import './TestimonialsCustomCard.scss';

const TestimonialsCustomCard = ({ data }) => {
  const { title, image, text, alt } = data;

  return (
    <div className='pp-testimonials-custom-card'>
      <p className='pp-testimonials-custom-card__caption'>{text}</p>
      <div className='pp-testimonials-custom-card__author'>
        {/* <img src={image} alt={alt} /> */}
        <div className='pp-testimonials-custom-card__author--wrap'>
          <div className='pp-testimonials-custom-card__author--title'>
            {title}
          </div>
          <div className='pp-testimonials-custom-card__author--description'>
            {alt}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsCustomCard;
