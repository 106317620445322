/* External */
import React from 'react';
import { Link } from 'react-router-dom';

/* Components */
import CustomButton from './../CustomButton';

/* Styles */
import './NavBarDesktop.scss';

/* Others */
import { redirectToHome } from './../../Utils/Functions/Miscellaneous';

const NavBarDesktop = () => {
  return (
    <div className='pp-nav-bar-desktop'>
      <div className='pp-nav-bar-desktop__section-container'>
        <img
          src='/Assets/Images/navbar-logo.svg'
          alt='BeHealthy'
          onClick={redirectToHome}
        />
      </div>
      <div className='pp-nav-bar-desktop__section-container'>
        <Link to='/#HowItWorks'>Our Program</Link>
        <Link to='/#Vertical'>Applications</Link>
        <Link to='/#WhyBeHealthy'>Benefits</Link>
        <Link to='/#DeliveryOptions'>Delivery Options</Link>
        <Link to='/#Testimonials'>Testimonials</Link>
      </div>
      <CustomButton
        text='Request a demo'
        filled={true}
        href={encodeURI(window.location.search).includes('utm') 
        ? encodeURI(window.location.search) + '#ContactForm'
        : '/#ContactForm' }
        customContainerClass='pp-nav-bar-desktop__section-container'
        customLinkClass='pp-nav-bar-desktop__section-container--button'
        isNavigation={true}
      />
    </div>
  );
};

export default NavBarDesktop;
