import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

/* Styles */
import './TermsAndConditions.scss';

/* Components */
import Footer from './../Footer';

/* Others */
import { TERMS_AND_CONDITIONS } from '../../Utils/Constants/Data';
import {
  useNavbar,
  useGetCurrentBreakpoint,
} from './../../Utils/Functions/Hooks';

const TermsAndConditionsPage = () => {
  const currentBreakpoint = useGetCurrentBreakpoint(useState, useEffect);
  const currentNavBar = useNavbar(useState, currentBreakpoint);
  return (
    <div className='pp-terms-and-conditions'>
      <Helmet>
        <title>BeHealthy Rewards Program | BeHealthy By Globant X</title>
      </Helmet>
      {currentNavBar}
      <div className='pp-terms-and-conditions__main-section'>
        <h2 className='pp-terms-and-conditions__main-section-title'>
          Terms And Conditions
        </h2>
        <p className='pp-terms-and-conditions__main-section-paragraph'>
          The following Terms and Conditions govern the use{' '}
          <a
            className='pp-terms-and-conditions__main-section-paragraph--link'
            href='https://behealthy.globant.com/'
            target='_blank'
          >
            www.behealthy.globant.com
          </a>{' '}
          and the services that may be offered in the site; the information
          provided in the sites and the ability to register into our database,
          receive newsletters and promotional emails; as well as any related
          links (collectively, our "Sites"). Please read the following Terms and
          Conditions carefully.
        </p>
        <p className='pp-terms-and-conditions__main-section-paragraph'>
          By using any one of our Sites, you understand and expressly agree to
          be legally bound by these Terms and Conditions and to follow these
          Terms and Conditions and all applicable laws and regulations governing
          our Sites
        </p>
        <p className='pp-terms-and-conditions__main-section-paragraph'>
          The Terms and Conditions shall supersede any subsequent terms or
          conditions included with any purchase order, whether or not such terms
          or conditions are signed by Globant ("Globant," "we" or "us").
        </p>
        <p className='pp-terms-and-conditions__main-section-paragraph'>
          We reserve the right to change these Terms and Conditions at any time,
          effective immediately upon posting on our Sites. If you violate these
          Terms and Conditions, we may terminate your use of the Sites, bar you
          from future use of the Sites, and/or take appropriate legal action
          against you.
        </p>
        {TERMS_AND_CONDITIONS.map(({ id, title, text }) => (
          <div
            className='pp-terms-and-conditions__main-section-second-block'
            key={id}
          >
            <h2 className='pp-terms-and-conditions__main-section-subtitle'>
              {title}
            </h2>
            <p className='pp-terms-and-conditions__main-section-paragraph'>
              {text}
            </p>
          </div>
        ))}
      </div>
      <div className='pp-terms-and-conditions__footer-section'>
        <Footer />
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
