/* External */
import React, { useEffect } from 'react';

/* Components */
import { SOCIAL_MEDIA } from './../../Utils/Constants/Data';
import CustomIcon from './../CustomIcon';

/* Styles */
import './Footer.scss';

const Footer = () => {

  return (
    <div className='pp-footer'>
      <div className='pp-footer__top'>
        <img
          className='pp-footer__logo'
          src='/Assets/Images/logo-footer.svg'
          alt='BeHealthy'
        />
        <div className='pp-footer__legal-links'>
          <span className='pp-footer__legal-links--title'>Legal</span>
        
          <ul className='pp-footer__legal-links--list'>
            <li className='pp-footer__legal-links--list-item'>
              <a href='/terms-and-conditions' target='_blank' rel='noreferrer'>
                Terms & Conditions
              </a>
            </li>
            <li className='pp-footer__legal-links--list-item'>
              <a
                href='/privacy-policy'
                target='_blank'
                rel='noreferrer'
              >
                Privacy Policy
              </a>
            </li>
            <li className='pp-footer__legal-links--list-item'>
              <a href='/cookie-policy' target='_blank' rel='noreferrer'>
                Cookie Policy
              </a>
            </li>
            <li className='pp-footer__legal-links--list-item'>
              <a
                role="button"
                onClick={(e)=>{
                  if (typeof window['UC_UI'] !== 'undefined') {
                      window['UC_UI'].showSecondLayer()
                  }
                  e.preventDefault()
               }} 
              >
                Cookies Preferences
              </a>
              
            </li>
          </ul>
        </div>
      </div>
      <div className='pp-footer__bottom'>
        <div className='pp-footer__social-media-container'>
          {SOCIAL_MEDIA.map((dataEntry, index) => (
            <CustomIcon data={dataEntry} key={index} />
          ))}
        </div>
        <p className='pp-footer__copyright'>All rights reserved Globant 2023</p>
      </div>
    </div>
  );
};

export default Footer;
