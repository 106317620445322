/* External */
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

/* Components */
import Footer from "./../Footer";

/* Styles */
import "./ThankyouPage.scss";

import {
  useNavbar,
  useGetCurrentBreakpoint,
  useResponsiveImageSource,
} from "./../../Utils/Functions/Hooks";

const ThankyouPage = ({ }) => {
  const currentBreakpoint = useGetCurrentBreakpoint(useState, useEffect);
  const thankyouResponsiveImageSource = useResponsiveImageSource(
    currentBreakpoint,
    'thankyou'
  );
  const currentNavBar = useNavbar(useState, currentBreakpoint);

  return (
    <div className="mp-thankyoupage">
      <Helmet>
      <title>BeHealthy Rewards Program | BeHealthy By Globant X</title>
      </Helmet>
      {currentNavBar}
        <div className="mp-thankyoupage__wrapper">
          <div className="mp-thankyoupage__wrapper--content">
            <div className='mp-thankyoupage__wrapper--content-image'>
              <img
                src={thankyouResponsiveImageSource}
                alt='Thank you'
              />
            </div>
            <div className='mp-thankyoupage__wrapper--content-text'>
                <h2 className='mp-thankyoupage__wrapper--content-text--title'>
                  Thank you!
                </h2>
                <p className='mp-thankyoupage__wrapper--content-text--description'>
                  Check your email soon for further information.
                </p>
            </div>
          </div>
        </div>
        <div
        id={"Footer"}
        className="pp-support-page__section pp-support-page__section--black pp-support-page__section--on-big"
      >
        <span className="pp-support-page__section--wrapper">
          <Footer />
        </span>
      </div>
    </div>
  );
};

export default ThankyouPage;

