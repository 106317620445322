export const SLIDER_DATA_TESTIMONIAL = [
  {
    title: '',
    image: '',
    text: 'Getting active has been a goal of mine but I needed an incentive. The only exercise I can really do is walking but it is amazing how much energy I can use up just on simple walks around my neighbourhood.',
    alt: 'Program Participant',
  },
  {
    title: 'Guibert Englebienne',
    image: '',
    text: 'Globant is committed to a healthier world and the Globant BeHealthy program, developed with Apple, is a step towards that. Close activity rings to earn stars and collect stars to get rewards. It is simple but powerful.',
    alt: 'Co-founder, LATAM & Globant X President',
  },
  {
    title: '',
    image: '',
    text: "Before the BeHealthy app, I knew my rings were there but I didn't pay a lot of attention to them... now I'm always checking on how much I need to move/stand to achieve my goals.",
    alt: 'Program Participant',
  },
];

export const HOW_IT_WORKS = [
  {
    title: 'Meet personal daily targets to earn a star',
    src: '/Assets/Images/targets-earns.png',
    text: '',
    alt: '',
  },
  {
    title: 'Collect stars to meet a personalized monthly goal',
    src: '/Assets/Images/collect-starts.png',
    text: '',
    alt: '',
  },
  {
    title: 'Meet the monthly goal to earn rewards',
    src: '/Assets/Images/goal-earn.png',
    text: '',
    alt: '',
  },
];

export const STATS = [
  {
    id: 1,
    src: '/Assets/Images/engaged-sessions.png',
    alt: 'Engaged sessions',
    value: '16',
    text: 'engaged sessions per user per month',
  },
  {
    id: 2,
    src: '/Assets/Images/active-days.png',
    alt: 'More active days',
    value: '2.3x',
    text: 'more active days per month after 12 months',
  },
  {
    id: 3,
    src: '/Assets/Images/exercise-minutes.png',
    alt: 'More minutes of exercise',
    value: '76%',
    text: 'more minutes of exercise after 12 months',
  },
];

export const DELIVERY_OPTIONS = [
  {
    title: 'Private-Label Applications',
    text: 'White-label applications in iOS and Android that can be configured and branded for sponsor. This solution can be deployed in less than two months.',
  },
  {
    title: 'SDK',
    text: 'Program elements can be integrated into existing client applications. Ideal for driving engagement with current platforms. Deployment timing is highly dependent on client development and integration.',
  },
  {
    title: 'API',
    text: 'Our current API is capable of displaying participant achievements in external dashboards.',
  },
];

export const SOCIAL_MEDIA = [
  {
    src: '/Assets/Images/facebook-icon.svg',
    alt: 'Facebook',
    link: 'https://www.facebook.com/Globant',
  },
  {
    src: '/Assets/Images/linkedin-icon.svg',
    alt: 'Linkedin',
    link: 'https://www.linkedin.com/company/globant/',
  },
  {
    src: '/Assets/Images/twitter-icon.svg',
    alt: 'Twitter',
    link: 'https://twitter.com/globant',
  },
  {
    src: '/Assets/Images/instagram-icon.svg',
    alt: 'Instagram',
    link: 'https://www.instagram.com/globant/',
  },
  {
    src: '/Assets/Images/youtube-icon.svg',
    alt: 'Youtube',
    link: 'https://www.youtube.com/c/GlobantPlus',
  },
];

export const TERMS_AND_CONDITIONS = [
  {
    id: 1,
    title: 'Copyright and trademark notice',
    text: (
      <>
        The contents of all material available on our Sites are copyrighted by
        Globant unless otherwise indicated. All rights are reserved and content
        may not be reproduced, downloaded, disseminated, or transferred, in any
        form or by any means, except with the prior written agreement of Globant
        or as indicated below.
        <br />
        <br />
        Permitted Use: Users may download pages or other content for their own
        personal use on a single computer, but no part of such content may be
        otherwise or subsequently reproduced, downloaded, disseminated, or
        transferred, in any form or by any means, except with the prior written
        agreement of, and with express attribution to, Globant. You agree that
        you are only authorized to visit, view and to retain a copy of pages of
        the Sites for your own personal use, and that you shall not duplicate,
        download, publish, modify or otherwise distribute the material on the
        Sites for any purpose other than for personal use, unless otherwise
        specifically authorized by us to do so. You also agree not to deep-link
        to the site for any purpose, unless specifically authorized by us to do
        so.
      </>
    ),
  },
  {
    id: 2,
    title: 'General legal notice and liability disclaimer',
    text: (
      <>
        We make available our Sites and the information and services contained
        herein “as is”, while Globant makes every effort to present accurate and
        reliable information on our Sites, Globant does not endorse, approve or
        certify such information, nor does it guarantee the accuracy,
        completeness, efficacy, or timeliness of such information. Use of such
        information is voluntary, and reliance on it should only be undertaken
        after an independent review by qualified experts.
        <br />
        <br />
        Reference herein to any specific commercial product, process or service
        does not constitute or imply endorsement, recommendation or favoring by
        Globant.
        <br />
        <br />
        At certain places on this site, live "links" to other Web sites can be
        accessed. Such external sites contain information created, published,
        maintained, or otherwise posted by institutions or organizations
        independent of Globant. Globant does not endorse, approve, certify, or
        control these external sites and does not guarantee the accuracy,
        completeness, efficacy, or timeliness of information located at such
        sites. Use of any information obtained from such sites is voluntary, and
        reliance on it should only be undertaken after an independent review by
        qualified experts.
        <br />
        <br />
        Globant assumes no responsibility for consequences resulting from use of
        the information contained herein, or from use of the information
        obtained at linked sites, or in any respect for the content of such
        information. Globant is not responsible for, and expressly disclaims all
        liability for, damages of any kind arising out of use, reference to,
        reliance on, or performance of such information.
      </>
    ),
  },
  {
    id: 3,
    title: 'Privacy policy',
    text: (
      <>
        Globant values individual privacy and we want to give our Site's
        visitors the opportunity to know what information we collect about them
        and how it is treated by us. If you would like more information, please
        visit our{' '}
        <a
          className='pp-terms-and-conditions__main-section-paragraph--link'
          target='_blank'
          rel='noopener noreferrer'
          href='/privacy-policy'
        >
          privacy policy page
        </a>
        .
      </>
    ),
  },
  {
    id: 4,
    title: 'Severability',
    text: 'The invalidity or unenforceability of any particular provision of this Policy shall not affect the remaining provisions hereof, and this Policy shall be construed in all respects as if such invalid or unenforceable provision had been omitted.',
  },
];

export const FAQS = [
  {
    id: 1,
    question: 'How does the program work?',
    answer:
      'Globant BeHealthy is a reward program that allows you to earn USD25 (US) or CAD25 (Canada) every month you complete monthly goals aligned with your main activity targets (Move, Exercise, and Stand for iOS; Heart Points and Steps for Android). You will receive one star for completing all your targets in a single day and if you reach the required number of stars in the month you earn your reward! Your monthly goal will adjust each month depending on your progress.',
  },
  {
    id: 2,
    question: 'How will I get my reward?',
    answer:
      'Every month you meet the goal, a reward payment, plus a "gross-up" to cover applicable taxes, will be posted to your regular paycheck and reflected as a unique "BeHealthy" line item on your paystub. Payment will be made in your mid-month paycheck for rewards earned the prior month, as long as your BeHealthy data is updated and in synch at the start of the new month. If your data is not updated then, your reward may be posted to the following check.',
  },
  {
    id: 3,
    question: 'How do I set the activity level in the Globant BeHealthy app?',
    answer:
      'When you first activate the account on the Globant BeHealthy app, you will be asked to provide your activity level as either light, moderate or high. This is used to set your initial goals, which will adjust over time based on your actual performance.',
  },
  {
    id: 4,
    question: 'How will my personal information be used & stored?',
    answer: (
      <>
        Your information is used to customize the Globant BeHealthy program for
        you. Primarily this data is used to determine your activity targets and
        to monitor and record your progress toward these daily targets and
        monthly goals. A number of safeguards and measures are in place,
        including ongoing monitoring, to keep your data encrypted and secure.
        You can learn more in the{' '}
        <a
          className='pp-faqs__accordion-container--link'
          id='link-faqs-styles'
          target='_blank'
          rel='noopener noreferrer'
          href='https://behealthy.globant.com/docs/EN/privacypolicy.pdf'
          style={{ display: 'inline-block' }}
        >
          Privacy Policy
        </a>
        .
      </>
    ),
  },
];

export const FAQS_SUPPORT_PROGRAM = [
  {
    id: 1,
    question: 'How does the program work?',
    answer:
      'Globant BeHealthy is a reward program that allows you to earn USD25 (US) or CAD25 (Canada) every month you complete monthly goals aligned with your main activity targets (Move, Exercise, and Stand for iOS; Heart Points and Steps for Android). You will receive one star for completing all your targets in a single day and if you reach the required number of stars in the month you earn your reward! Your monthly goal will adjust each month depending on your progress.',
  },
  {
    id: 2,
    question: "What happens if I don't achieve my monthly goal?",
    answer:
      "You must meet the goal in order to earn the reward. If you don't earn the reward one month, try again the next month!",
  },
  {
    id: 3,
    question: 'What do the Move, Exercise and Stand rings mean?',
    answer: (
      <>
        The Move ring shows how many calories you've burned today by being
        active.
        <br />
        <br /> The Exercise ring shows how many minutes of brisk activity you've
        completed and is measured by your increased heart rate.
        <br />
        <br /> The Stand ring shows the number of hours in which you've stood
        and moved for at least a minute.
      </>
    ),
  },
  {
    id: 4,
    question: 'What do the Heart Points and Steps arcs mean?',
    answer: (
      <>
        The Heart Points arc shows your daily target and the amount of points
        you scored for activity that raised your heart rate during the day.
        <br />
        <br /> The Steps arc shows your daily target and the amount of steps to
        took during the day.
      </>
    ),
  },
  {
    id: 5,
    question:
      'Are the Move, Exercise and Stand targets for the BeHealthy iOS app the same as those used by Apple Activity app?',
    answer: (
      <>
        Thirty minutes of Exercise and twelve Stand hours are the default values
        used by your Apple Activity app and are the same ones used in Globant
        BeHealthy. The Move goal used in Globant BeHealthy is unique. It is
        initially set based on your personal information including age, sex,
        height, weight and physical activity level. Over time the Globant
        BeHealthy Move goal will adjust, up or down, based on your actual
        performance. The more active you are, the higher it will go.
        <br />
        <br /> If you would like for your Apple Activity Move target to match
        the one used in Globant BeHealthy, you may adjust it through the
        Activity app on your Apple Watch. Swipe up, then tap Change Goals to
        adjust your Move Goal.
      </>
    ),
  },
  {
    id: 6,
    question:
      'Are the Heart Points and Steps targets for the BeHealthy Android app the same as those used by the Google Fit app?',
    answer: (
      <>
        The Heart Points and Steps targets used in Globant BeHealthy are unique.
        They are initially set based on your personal information and physical
        activity level. Over time the Globant BeHealthy targets will adjust, up
        or down, based on your actual performance. The more active you are, the
        higher they will go.
        <br />
        <br /> If you would like for your Google Fit daily goals to match the
        ones used in Globant BeHealthy, you may adjust them through your Profile
        inside the Google Fit app.
      </>
    ),
  },
  {
    id: 7,
    question: 'Can I edit my personal information?',
    answer:
      'It is important that your Globant BeHealthy account information is accurate before enrolling. You may update this information by contacting the Globant People Team. The program will also utilize information, such as height and weight, stored in your Apple Health app, Google Fit app or Fitbit profile, depending on the device that you use to participate in the program. It is also important that this information is up to date so that we can accurately set up program goals.',
  },
  {
    id: 8,
    question: 'How are my daily targets and monthly goals set?',
    answer: (
      <>
        <b>For iOS</b>, your initial Move target is set based on your personal
        information such as your age, sex, height, weight and physical activity
        level. Over time your Move target will adjust, up or down, based on your
        actual performance. The Exercise and Stand targets do not change over
        time. The Exercise target is 30 minutes a day, and to reach the Stand
        target you must stand and move for at least one minute per hour for 12
        hours per day.
        <br />
        <br /> <b>For Android</b>, your Heart Points and Steps targets are
        designed for you based on your physical activity level and they will
        adjust every week depending on your previous performance.
        <br />
        <br /> Each day you meet all your targets, you earn a star and each
        month you need a certain number of stars to earn your reward. During the
        first calendar month you join the program you will need to earn 9 stars
        to get the reward.
        <br />
        <br /> Over time, your monthly star goal will adapt to your
        accomplishments, helping you become more active. The more active you
        are, the higher your goal will be.
      </>
    ),
  },
  {
    id: 9,
    question: 'Can I collect or rollover stars from previous months?',
    answer:
      'No, stars are only valid for the month they were earned. At the start of each month, the star count resets to zero.',
  },
  {
    id: 10,
    question: 'How will I get my reward?',
    answer:
      'Every month you meet the goal, a USD25 (US) or CAD25 (Canada) payment, plus a "gross-up" to cover applicable taxes, will be posted to your regular paycheck and reflected as a unique "BeHealthy" line item on your paystub. Payment will be made in your mid-month paycheck for rewards earned the prior month, as long as your BeHealthy data is updated and in synch at the start of the new month. If your data is not updated then, your reward may be posted to the following check.',
  },
  {
    id: 11,
    question:
      'What if I earned my monthly required stars last month but I did not get my reward?',
    answer: (
      <>
        If your app is not up-to-date with your activities at the end of the
        month when rewards are processed, it is possible that the reward will
        not be posted to your account in that period.
        <br />
        <br /> The app is up-to-date when you can see your past performance in
        the monthly activity section of the application. To ensure your progress
        is updated, open the app and check your progress the last day of the
        month. Pending rewards from previous months are processed every Friday.
        If that is your case, you will receive your reward.
        <br />
        <br /> For any technical issues or bugs raise a ticket at Invgate -
        Globant Applications --> Mobile Apps -> BeHealthy <br />
        <br /> Any other questions about the program, please contact{' '}
        <a
          className='pp-faqs__accordion-container--link'
          id='link-faqs-styles'
          href='mailto:behealthy@globant.com'
        >
          behealthy@globant.com
        </a>
        .
      </>
    ),
  },
  {
    id: 12,
    question: 'Can I get a partial reward?',
    answer:
      'Rewards are only available if you meet the monthly goal for the number of stars. If you do not reach the minimum number of stars, you will not receive a reward that month.',
  },
  {
    id: 13,
    question: 'Who can help me if I have a problem?',
    answer: (
      <>
        If you have any difficulties with the program or enrollment, please send
        an email to{' '}
        <a
          className='pp-faqs__accordion-container--link'
          id='link-faqs-styles'
          href='mailto:behealthy@globant.com'
        >
          behealthy@globant.com
        </a>
        .
      </>
    ),
  },
];

export const FAQS_SUPPORT_ACTIVATION = [
  {
    id: 1,
    question: 'How do I set up the program on my device?',
    answer:
      'Up to 2 business days after your enrollment, you will receive a welcome email. This email will have a link for you to download the Globant BeHealthy app from the App Store or Play Store depending on the platform you selected. When your download is ready, follow the app instructions to activate your account.',
  },
  {
    id: 2,
    question:
      'Is the password for the Globant BeHealthy app the same as the password I use for Globant?',
    answer: (
      <>
        If you are an iOS user, yes, it is the same. If you need any support
        regarding your password, please use the Globant Password Manager.
        <br />
        <br />
        If you plan to use Globant BeHealthy with an Android device, you can
        either sign up with Globant authentication or creating a new password.
        However, we strongly recommend you to sign up with Globant
        authentication because that will be the only method available in the
        future.
      </>
    ),
  },
  {
    id: 3,
    question: 'How do I set the activity level in the Globant BeHealthy app?',
    answer:
      'When you first activate the account on the Globant BeHealthy app, you will be asked to provide your activity level as either light, moderate or high. This is used to set your initial goals, which will adjust over time based on your actual performance.',
  },
  {
    id: 4,
    question: 'How will my personal information be used & stored?',
    answer: (
      <>
        Your information is used to customize the Globant BeHealthy program for
        you. Primarily this data is used to determine your activity targets and
        to monitor and record your progress toward these daily targets and
        monthly goals. A number of safeguards and measures are in place,
        including ongoing monitoring, to keep your data encrypted and secure.
        You can learn more in the{' '}
        <a
          className='pp-faqs__accordion-container--link'
          id='link-faqs-styles'
          target='_blank'
          rel='noopener noreferrer'
          href='https://behealthy.globant.com/docs/EN/privacypolicy.pdf'
        >
          Privacy Policy
        </a>
        .
      </>
    ),
  },
  {
    id: 5,
    question: 'Who can access my personal information?',
    answer: (
      <>
        The Globant BeHealthy program only has access to personal information
        you give permission for, and it only requests information which is
        needed for you to participate in the program and to be awarded for your
        achievements. Only program administrators have access to the information
        you have provided and only when it is necessary to provide technical
        support in resolution of a submitted issue. You can learn more in the{' '}
        <a
          className='pp-faqs__accordion-container--link'
          id='link-faqs-styles'
          target='_blank'
          rel='noopener noreferrer'
          href='https://behealthy.globant.com/docs/EN/privacypolicy.pdf'
        >
          Privacy Policy
        </a>
        .
      </>
    ),
  },
  {
    id: 6,
    question: 'Where can I find the enrollment Terms & Conditions?',
    answer: (
      <>
        You can check them{' '}
        <a
          className='pp-faqs__accordion-container--link'
          id='link-faqs-styles'
          target='_blank'
          rel='noopener noreferrer'
          href='https://behealthy.globant.com/docs/EN/termsandconditions.pdf'
        >
          here
        </a>
        .
      </>
    ),
  },
];

export const FAQS_SUPPORT_REQUIREMENTS = [
  {
    id: 1,
    question: 'Who is eligible for the Globant BeHealthy program?',
    answer: (
      <>
        To participate in the current pilot program you must be a full-time
        Globant employee based in the US or Canada.
        <br />
        <br /> To participate with the iOS version of the app, you will also
        need an iPhone and Apple Watch, Series 3 or newer. To participate with
        the Android version, you will need a Wear OS smartwatch or a Fitbit and
        a compatible cellphone.
        <br />
        <br /> If you are a Globant employee based in the US or Canada and want
        to purchase these devices, you can do it through the Wellness
        Reimbursement benefit offered by Globant.
      </>
    ),
  },
  {
    id: 2,
    question:
      'Why do you have to be a Globant employee based in the US or Canada to enroll?',
    answer:
      'The Globant BeHealthy program is currently only available as a pilot in that geographic area. This allows us to refine the program for wider release in the future.',
  },
  {
    id: 3,
    question: "What happens if I don't get the welcome email to the program?",
    answer: (
      <>
        You should receive the welcome email in the next 2 business days after
        submitting the enrollment form. Please search your inbox, including your
        spam folder, for your "Welcome to the Globant BeHealthy program" email.
        <br />
        <br /> If you think you haven’t received your welcome email in error,
        please contact support:{' '}
        <a
          className='pp-faqs__accordion-container--link'
          id='link-faqs-styles'
          href='mailto:behealthy@globant.com'
        >
          behealthy@globant.com
        </a>
      </>
    ),
  },
];

export const FAQS_SUPPORT_DEVICES = [
  {
    id: 1,
    question:
      'Do I need the Apple Watch or a smartwatch to participate? What happens if I lose my device?',
    answer: (
      <>
        You will need an Apple Watch series 3 or newer to participate in the
        Globant BeHealthy program for iOS.
        <br />
        <br /> For the Android version of the BeHealthy app, you will need a
        Wear OS smartwatch or a Fitbit and a compatible cellphone.
        <br />
        <br /> Without an Apple Watch (iOS) or smartwatch (Android) you are not
        able to reach your daily targets or earn stars toward your reward.
      </>
    ),
  },
  {
    id: 2,
    question: 'What model of Apple Watch is needed to participate?',
    answer:
      'You will need an Apple Watch series 3 or newer to participate in the Globant BeHealthy program.',
  },
  {
    id: 3,
    question:
      'What if I have a problem getting my Apple Watch through the Apple affiliate program?',
    answer: (
      <>
        Please send an email to{' '}
        <a
          className='pp-faqs__accordion-container--link'
          id='link-faqs-styles'
          href='mailto:behealthy@globant.com'
        >
          behealthy@globant.com
        </a>
      </>
    ),
  },
];

export const COOKIE_POLICY_QUESTIONS = [
  {
    id: 1,
    question: 'What is a Cookie?',
    answer:
      'A cookie is a file created by a website that contains small pieces of information that are sent between the server where this website is hosted, and the web browser used to access and view this website.',
  },
  {
    id: 2,
    question: 'For what purpose do we use cookies?',
    answer: (
      <>
        Through a cookie, the user can be identified by storing their activity
        history within a website, so that the most appropriate content can be
        offered according to its habits.
        <br />
        <br />
        When you visit a website for the first time, a cookie is saved in the
        browser with little information. The next time you visit the same site,
        the cookie allows you to configure the website and make the visit as
        personalized as possible.
      </>
    ),
  },
  {
    id: 3,
    question: 'How to control and delete cookies?',
    answer: (
      <>
        You can control and/or delete cookies as you wish – for details, see{' '}
        <a
          className='cookie-questions__links'
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.aboutcookies.org/'
        >
          aboutcookies.org
        </a>
        . You can delete all cookies that are already on your computer and you
        can set most browsers to prevent them from being placed. If you do this,
        however, you may have to manually adjust some preferences every time you
        visit a site and some services and functionalities may not work.
        <br />
        <br />
        These cookie settings are usually found in the 'options' or
        'preferences' menu of your internet browser. In order to understand
        these settings, the following links may be helpful. Otherwise you should
        use the 'Help' option in your internet browser for more details.
        <br />
        <br />
        <ul>
          <li>
            <a
              className='cookie-questions__links'
              target='_blank'
              rel='noopener noreferrer'
              href='https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d'
            >
              Cookie settings in Internet Explorer
            </a>
          </li>
          <li>
            <a
              className='cookie-questions__links'
              target='_blank'
              rel='noopener noreferrer'
              href='https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies'
            >
              Cookie settings in Firefox
            </a>
          </li>
          <li>
            <a
              className='cookie-questions__links'
              target='_blank'
              rel='noopener noreferrer'
              href='https://support.google.com/chrome/answer/95647?hl=en'
            >
              Cookie settings in Chrome
            </a>
          </li>
          <li>
            <a
              className='cookie-questions__links'
              target='_blank'
              rel='noopener noreferrer'
              href='https://support.apple.com/es-us/HT201265'
            >
              Cookie settings in Safari
            </a>
          </li>
        </ul>
        <br />
        To opt-out of third-parties collecting any data regarding your
        interaction on our website, please refer to their websites for further
        information.
        <br />
        <br />
        <a
          className="pp-custom-button__btn pp-custom-button__btn--filled"
          role="button"
          onClick={(e)=>{
            if (typeof window['UC_UI'] !== 'undefined') {
                window['UC_UI'].showSecondLayer()
            }
            e.preventDefault()
          }} 
        >
          Cookie Settings
        </a>
        <br />
        To find out more about cookies, including how to see what cookies have
        been set and how to manage and delete them, visit{' '}
        <a
          className='cookie-questions__links'
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.aboutcookies.org/'
        >
          aboutcookies.org
        </a>{' '}
        or{' '}
        <a
          className='cookie-questions__links'
          target='_blank'
          rel='noopener noreferrer'
          href='https://allaboutcookies.org/'
        >
          allaboutcookies.org
        </a>
        .
      </>
    ),
  },
];

export const COOKIE_POLICY_TYPES = [
  {
    id: 1,
    description: (
      <>
        <h4 className='cookie-types-duration__subsection-title'>
          1. First and third-party cookies:
        </h4>{' '}
        <p>
          Whether a cookie is 'first' or 'third' party refers to the domain
          placing the cookie. First-party cookies are those set by a Web site
          that is being visited by the user at the time.
        </p>
      </>
    ),
  },
  {
    id: 2,
    description: (
      <>
        <h4 className='cookie-types-duration__subsection-title'>
          2. Third-party cookies:
        </h4>{' '}
        <p>
          These are cookies that are set by a domain other than that of the Web
          site being visited by the user. If a user visits a Web site and
          another entity sets a cookie through that Web site, this would be a
          third-party cookie.
        </p>
      </>
    ),
  },
];

export const COOKIE_DURATION = [
  {
    id: 1,
    text: (
      <>
        <h4 className='cookie-types-duration__subsection-title'>
          1. Session cookies:
        </h4>{' '}
        <p>
          These cookies allow Web site operators to link the actions of a user
          during a browser session. A browser session starts when a user opens
          the browser window and finishes when they close the browser window.
          Session cookies are created temporarily. Once you close the browser,
          all session cookies are deleted.
        </p>
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        <h4 className='cookie-types-duration__subsection-title'>
          2. Persistent cookies:
        </h4>{' '}
        <p>
          These cookies remain on a user’s device for the period of time
          specified in the cookie. They are activated each time that the user
          visits the Web site that created that particular cookie.
        </p>
        <br />
        <p>
          We only use the types of cookies described above. We don’t use
          so-called ‘specialist cookies’. In addition, Globant uses Google
          Analytics for the only purpose of obtaining the report of the activity
          they are provide.
        </p>
      </>
    ),
  },
];

export const COOKIE_POLICY_LIST = [
  {
    id: 1,
    title: 'First-party cookies',
    cookie: (
      <>
        <div className='pp-cookie-table__columns-subtitles--padding-bigger-break-tab1'>
          pardot
        </div>
      </>
    ),
    category: (
      <>
        <div className='pp-cookie-table__columns-subtitles--padding-bigger-break-tab1'>
          Advertising
        </div>
      </>
    ),
    purpose: (
      <>
        <div className='pp-cookie-table__columns-subtitles--padding-pur-answer-tab1 pp-cookie-table__rows-text-spacing'>
          The "pardot" cookie name associated with services from marketing
          automation and lead generation platform Pardot.
        </div>
      </>
    ),
    duration: (
      <>
        <div className='pp-cookie-table__columns-subtitles--padding-bigger-break-tab1'>
          Session
        </div>
      </>
    ),
    expiration: (
      <>
        <div className='pp-cookie-table__columns-subtitles--padding-bigger-break-tab1'>
          NA
        </div>
      </>
    ),
  },
  {
    id: 2,
    cookie: (
      <>
        <div className='pp-cookie-table__columns-subtitles--padding-bigger-break-tab2'>
          _ga
        </div>
      </>
    ),
    category: (
      <>
        <div className='pp-cookie-table__columns-subtitles--padding-bigger-break-tab2'>
          Performance & Analytics
        </div>
      </>
    ),
    purpose:
      'The "_ga" cookie is used to determine unique visitors to the site and it is updated with each page view. Additionally, this cookie is provided with a unique ID that Google Analytics uses to ensure both the validity and accessibility of the cookie as an extra security measure.',
    duration: (
      <>
        <div className='pp-cookie-table__columns-subtitles--padding-bigger-break-tab2'>
          Persistent
        </div>
      </>
    ),
    expiration: (
      <>
        <div className='pp-cookie-table__columns-subtitles--padding-bigger-break-tab2'>
          2 years from set/update.
        </div>
      </>
    ),
  },
];

export const COUNTRIES_LIST = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];
