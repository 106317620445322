/* External */
import React from 'react';

/* Styles */
import './CustomIcon.scss';

const CustomIcon = ({ data }) => {
  const { src, alt, link } = data;
  return (
    <div className='pp-custom-icon'>
      <a href={link} target='_blank' rel='noreferrer'>
        <img className='pp-custom-icon__img' src={src} alt={alt} />
      </a>
    </div>
  );
};

export default CustomIcon;
