/* Components */
import NavBarMobile from './../../Components/NavBarMobile';
import NavBarDesktop from './../../Components/NavBarDesktop';

/* Others */
import {
  SCREEN_SIZE_VERY_SMALL,
  SCREEN_SIZE_SMALL,
  SCREEN_SIZE_MEDIUM_SMALL,
  SCREEN_SIZE_MEDIUM,
  SCREEN_SIZE_MEDIUM_BIG,
  SCREEN_SIZE_BIG,
  SCREEN_SIZE_VERY_BIG,
} from './../Constants/Breakpoints';

export const useWindowSize = (useState, useEffect) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const useGetCurrentBreakpoint = (useState, useEffect) => {
  const { width } = useWindowSize(useState, useEffect);
  let currentBreakpoint = SCREEN_SIZE_VERY_SMALL;

  if (width > 0 && width < 375) {
    currentBreakpoint = SCREEN_SIZE_VERY_SMALL;
  } else if (width >= 375 && width < 412) {
    currentBreakpoint = SCREEN_SIZE_SMALL;
  } else if (width >= 412 && width < 414) {
    currentBreakpoint = SCREEN_SIZE_MEDIUM_SMALL;
  } else if (width >= 414 && width < 768) {
    currentBreakpoint = SCREEN_SIZE_MEDIUM;
  } else if (width >= 768 && width < 1024) {
    currentBreakpoint = SCREEN_SIZE_MEDIUM_BIG;
  } else if (width >= 1024 && width < 1440) {
    currentBreakpoint = SCREEN_SIZE_BIG;
  } else if (width >= 1440) {
    currentBreakpoint = SCREEN_SIZE_VERY_BIG;
  }

  return currentBreakpoint;
};

export const useNavbar = (useState, currentBreakpoint) => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const navBarProps = {
    navbarOpen: navbarOpen,
    toggleNavBar: () => setNavbarOpen(!navbarOpen)
  };
  let currentNavBar;

  switch (currentBreakpoint) {
    case SCREEN_SIZE_VERY_BIG:
      navbarOpen && setNavbarOpen(false);
      currentNavBar = <NavBarDesktop />;
      break;

    case SCREEN_SIZE_BIG:
    case SCREEN_SIZE_MEDIUM_BIG:
    case SCREEN_SIZE_MEDIUM:
    case SCREEN_SIZE_MEDIUM_SMALL:
    case SCREEN_SIZE_SMALL:
    case SCREEN_SIZE_VERY_SMALL:
    default:
      currentNavBar = <NavBarMobile {...navBarProps} />;
      break;
  }

  return currentNavBar;
};

export const useResponsiveImageSource = (currentBreakpoint, imageId) => {
  let image = '';

  switch (currentBreakpoint) {
    case SCREEN_SIZE_VERY_SMALL:
    case SCREEN_SIZE_SMALL:
    case SCREEN_SIZE_MEDIUM_SMALL:
    case SCREEN_SIZE_MEDIUM:
      image = `/Assets/Images/${imageId}_375.png`;
      break;
    case SCREEN_SIZE_BIG:
    case SCREEN_SIZE_VERY_BIG:
    default:
      image = `/Assets/Images/${imageId}_1440.png`;
      break;
  }

  return image;
};
