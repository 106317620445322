/* External */
import React, { useState, useEffect } from 'react';

/* Styles */
import './ContactForm.scss';

/* Other */
import { COUNTRIES_LIST } from '../../Utils/Constants/Data';
import {
  useGetCurrentBreakpoint,
  useResponsiveImageSource,
} from '../../Utils/Functions/Hooks';

import {
  SCREEN_SIZE_VERY_SMALL,
  SCREEN_SIZE_SMALL,
  SCREEN_SIZE_MEDIUM_SMALL,
  SCREEN_SIZE_MEDIUM,
  SCREEN_SIZE_MEDIUM_BIG,
  SCREEN_SIZE_BIG,
  SCREEN_SIZE_VERY_BIG,
} from '../../Utils/Constants/Breakpoints';

const ContactForm = ({}) => {
  const currentBreakpoint = useGetCurrentBreakpoint(useState, useEffect);
  const smartWatchResponsiveImageSource = useResponsiveImageSource(
    currentBreakpoint,
    'smartwatch'
  );
  const [utm_campaign, setUtm_campaign] = useState('(not set)')
  const [utm_content, setUtm_content] = useState('(not set)')
  const [utm_medium, setUtm_medium] = useState('(none)')
  const [utm_source, setUtm_source] = useState('(direct)')
  const [utm_term, setUtm_term] = useState('(not set)')
  const [reload, setReload] = useState(false)
  const [consent, setConsent] = useState(' ')

  const handleChecked = (e) => {
    const isChecked = e ? e.target.checked : false;
    isChecked ? setConsent("true") : setConsent(" ");
  };

  const reloadHideFields = () => {
    setReload(!reload);
  };


  useEffect(() => {
    handleChecked();
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.get('utm_campaign') ? setUtm_campaign(urlParams.get('utm_campaign')) : setUtm_campaign('(not set)');
    urlParams.get('utm_content') ? setUtm_content(urlParams.get('utm_content')) : setUtm_content('(not set)');
    urlParams.get('utm_medium') ? setUtm_medium(urlParams.get('utm_medium')) : setUtm_medium('(none)');
    urlParams.get('utm_source') ? setUtm_source(urlParams.get('utm_source')) : setUtm_source('(direct)');
    urlParams.get('utm_term') ? setUtm_term(urlParams.get('utm_term')) : setUtm_term('(not set)') ;
  }, [reload])

  const textSection = (
    <div className='pp-smartwatch__left_on_big'>
      <h2 className='pp-smartwatch__title'>Move in the right direction with BeHealthy</h2>
      <p className='pp-smartwatch__description pp-smartwatch__description--first-paragraph'>
        To create a customized program for your organization, contact us.
      </p>
    <form 
      action="https://more.globant.com/l/497451/2023-01-19/6vstk2" 
      method="POST"
      className='mp-st-form__form'>
      <div className='mp-st-form__form-field'>
        <input
          className='mp-st-form__form-field--input'
          id='first-name'
          type='text'
          name='first-name'
          required
          placeholder='First Name *'
        />
      </div>
      <div className='mp-st-form__form-field'>
        <input
          className='mp-st-form__form-field--input'
          id='last-name'
          type='text'
          name='last-name'
          required
          placeholder='Last Name *'
        />
      </div>
      <div className='mp-st-form__form-field'>
        <input
          className='mp-st-form__form-field--input'
          id='email'
          type='email'
          name='email'
          required
          placeholder='Corporate email adress *'
          onChange={reloadHideFields}
        />
      </div>
      <div className='mp-st-form__form-field'>
        <input
          className='mp-st-form__form-field--input'
          id='job-title'
          type='text'
          name='job-title'
          required
          placeholder='Job Title *'
        />
      </div>
      <div className='mp-st-form__form-field'>
        <div style={{ position: "relative" }}>
          <select
            className='mp-st-form__form-field--select'
            required
            id='country'
            defaultValue="default"
          >
            <option value="default" disabled selected>Country *</option>
            {
            COUNTRIES_LIST.map( (country) => 
              <option 
                key={country.code}
                value={country.name}>
                {country.name}
              </option> 
            )
          }
          </select>
          <span className='mp-st-form__form-field--select-arrow'></span>
        </div>

        <input
          className='mp-st-form__form-field--input'
          id='phone'
          type='text'
          name='phone'
          placeholder='Phone'
        />
      </div>
      <div className='mp-st-form__form-field'>
        <textarea
          className='mp-st-form__form-field--textarea'
          id='your-challenge'
          name='your-challenge'
          placeholder='Your challenge'
        />
      </div>
      <div className="mp-st-form__form-field">
        <input 
          id="consent" 
          type="checkbox" 
          name="consent" 
          onChange={handleChecked}
          className='mp-st-form__form-field--checkbox'
          value={consent}
        />
          <span className="mp-st-form__form-field--checkbox-span"></span>
          <div className="mp-st-form__form-field--checkbox-text">
            I would like to sign up with my email address to receive Globant communications with updates, valuable resources and useful tips.
          </div>
      </div>
      <div className="mp-st-form__form-field--disclaimer">
        By submitting this form you confirm that you agree to Globant's <a href='/privacy-policy' target="_blank" className="mp-st-form__form-field--disclaimer-link">Privacy Policy</a>.
      </div>
        {/* Inputs hide */}
          <input 
            style={{ opacity:"0", "height": "0" }}
            type="checkbox" 
            name="privacy_policy_acknowledgement"
            id="privacy_policy_acknowledgement" 
            value={true} 
            checked
            readOnly/>
          <input type="hidden" value={utm_campaign} name="utm_campaign"/>
          <input type="hidden" value={utm_content} name="utm_content"/>
          <input type="hidden" value={utm_medium} name="utm_medium"/>
          <input type="hidden" value={utm_source} name="utm_source"/>
          <input type="hidden" value={utm_term} name="utm_term"/>
          <div style={{ opacity:"0", "height": "0" }}>
            <label for="pardot_extra_field">Comments</label>
            <input type="text" id="pardot_extra_field" name="pardot_extra_field"/>
          </div>
        {/*End Inputs hide */}
        <div className="pp-custom-button ">
          <input 
            id="submit"
            type="submit" 
            value="Request a demo"
            className="pp-custom-button__btn pp-custom-button__btn--filled mp-st-form__form-field--button" 
            required="required"
            name="submit" />
        </div>
    </form>
    </div>
  );

  const imageSection = (
    <div className='pp-smartwatch__right_on_big'>
      <img
        src={smartWatchResponsiveImageSource}
        alt='Move in the right direction with BeHealthy'
        className='pp-smartwatch__image'
      />
    </div>
  );

  let orderedSections = [textSection, imageSection];

  switch (currentBreakpoint) {
    case SCREEN_SIZE_VERY_BIG:
    case SCREEN_SIZE_BIG:
    case SCREEN_SIZE_MEDIUM_BIG:
      orderedSections = [imageSection, textSection];
      break;

    case SCREEN_SIZE_MEDIUM:
    case SCREEN_SIZE_MEDIUM_SMALL:
    case SCREEN_SIZE_SMALL:
    case SCREEN_SIZE_VERY_SMALL:
    default:
      orderedSections = [textSection, imageSection];
      break;
  }

  return (
    <div className='pp-smartwatch'>
      {orderedSections[0]}
      {orderedSections[1]}
    </div>
  );
};

export default ContactForm;
