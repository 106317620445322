/* External */
import React from 'react';

/* Styles */
import './CookieTypes.scss';

/* Other */
import {
  COOKIE_POLICY_TYPES,
  COOKIE_DURATION,
} from '../../Utils/Constants/Data';

const CookieConfiguration = () => {
  return (
    <div className='cookie-types-duration'>
      <h2 className='cookie-types-duration__title'>What cookies do we use?</h2>
      <div className='cookie-types-duration__text-block'>
        <p className='cookie-types-duration__text'>
          Globant may use the following cookies:
        </p>
        <h3 className='cookie-types-duration__subtitle'>Types of Cookies</h3>
        {COOKIE_POLICY_TYPES.map(({ id, description }, index) => (
          <div
            key={index + 1}
            className={`cookie-types-duration__mode-list-block cookie-types-duration__mode-list-block${
              id === 1 ? '--first-paragraph' : ''
            }`}
          >
            <div className='cookie-types-duration__text--map-layout'>
              {description}
            </div>
          </div>
        ))}
        <h3 className='cookie-types-duration__subtitle'>Duration of Cookies</h3>
        {COOKIE_DURATION.map(({ id, text }, index) => (
          <div
            key={index + 1}
            className={`cookie-types-duration__mode-list-block cookie-types-duration__mode-list-block${
              id === 1 ? '--first-paragraph' : ''
            }`}
          >
            <div className='cookie-types-duration__text--map-layout'>
              {text}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CookieConfiguration;
