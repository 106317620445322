/* External */
import React from 'react';

/* Styles */
import './Vertical.scss';

const Vertical = () => {
  return (
    <div className='pp-vertical'>
      <div className='pp-vertical__side-by-side-container'>
        <div className='pp-vertical__side-by-side-container--half'>
          <h2 className='pp-vertical__title'>
            Strengthen customer and employee wellness while elevating the health
            of your business.
          </h2>
          <p className='pp-vertical__paragraph'>
            BeHealthy is highly configurable and can be adapted to serve a
            number of goals.
          </p>
        </div>
        <div className='pp-vertical__side-by-side-container--half'>
          <ul className='pp-vertical__list'>
            <li className='pp-vertical__list--item'>
              <div className='pp-vertical__list--item-top'>
                <img src='/Assets/Images/check-list-bullet.svg' alt='Check' />
                <p>Insurance (Health and Life)</p>
              </div>
              <p className='pp-vertical__list--item-bottom'>
                Ensure wellness among your members.
              </p>
            </li>
            <li className='pp-vertical__list--item'>
              <div className='pp-vertical__list--item-top'>
                <img src='/Assets/Images/check-list-bullet.svg' alt='Check' />
                <p>Retail</p>
              </div>
              <p className='pp-vertical__list--item-bottom'>
                Encouraging wellness creates return customers.
              </p>
            </li>
            <li className='pp-vertical__list--item'>
              <div className='pp-vertical__list--item-top'>
                <img src='/Assets/Images/check-list-bullet.svg' alt='Check' />
                <p>Telco</p>
              </div>
              <p className='pp-vertical__list--item-bottom'>
                Put healthier habits in the hands of the customer.
              </p>
            </li>
            <li className='pp-vertical__list--item'>
              <div className='pp-vertical__list--item-top'>
                <img src='/Assets/Images/check-list-bullet.svg' alt='Check' />
                <p>Fan Engagement</p>
              </div>
              <p className='pp-vertical__list--item-bottom'>
                Connect between games with a game changer for fan's health.
              </p>
            </li>
            <li className='pp-vertical__list--item'>
              <div className='pp-vertical__list--item-top'>
                <img src='/Assets/Images/check-list-bullet.svg' alt='Check' />
                <p>Employers</p>
              </div>
              <p className='pp-vertical__list--item-bottom'>
                Healthy employees manage strong businesses.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Vertical;
