/* External */
import React from 'react';
import { Link } from 'react-router-dom';

/* Components */
import CustomButton from './../CustomButton';
import NavBarMobileToggleButton from './../NavBarMobileToggleButton';

/* Styles */
import './NavBarMobile.scss';

/* Others */
import {
  redirectToHome,
} from './../../Utils/Functions/Miscellaneous';

const NavBarMobile = ({ navbarOpen, toggleNavBar }) => {
  return (
    <>
      <div className='pp-nav-bar-mobile'>
        <img
          className='pp-nav-bar-mobile__logo'
          src='/Assets/Images/navbar-logo.svg'
          alt='BeHealthy'
          onClick={redirectToHome}
        />
        <NavBarMobileToggleButton
          navbarOpen={navbarOpen}
          toggleNavBar={toggleNavBar}
        />
      </div>
      <div className={`pp-nav-bar-mobile-list${navbarOpen ? '' : '--closed'}`}>
        <NavBarMobileToggleButton
          navbarOpen={navbarOpen}
          toggleNavBar={toggleNavBar}
        />
        <Link to='/#HowItWorks' onClick={toggleNavBar}>
          Our Program
        </Link>
        <Link to='/#Vertical' onClick={toggleNavBar}>
          Applications
        </Link>
        <Link to='/#WhyBeHealthy' onClick={toggleNavBar}>
          Benefits
        </Link>
        <Link to='/#DeliveryOptions' onClick={toggleNavBar}>
          Delivery Options
        </Link>
        <Link to='/#Testimonials' onClick={toggleNavBar}>
          Testimonials
        </Link>
        <CustomButton
          text='Request a demo'
          filled={true}
          href={encodeURI(window.location.search).includes('utm') 
          ? encodeURI(window.location.search) + '#ContactForm'
          : '/#ContactForm' }
          customLinkClass='pp-nav-bar-mobile-list__enroll-button'
          onClick={toggleNavBar}
          isNavigation={true}
        />
      </div>
    </>
  );
};

export default NavBarMobile;
