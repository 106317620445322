/* External */
import React from 'react';

/* Styles */
import './Accordion.scss';

/* Other */
import upFacingArrow from './../../Assets/Images/upward-facing-arrow.svg';
import downFacingArrow from './../../Assets/Images/downward-facing-arrow.svg';

const Accordion = ({
  question,
  answer,
  id,
  currentlyActiveQuestion,
  setCurrentlyActiveQuestion,
  questionNumber,
}) => {
  const isActive = currentlyActiveQuestion === questionNumber;

  return (
    <div className='pp-accordion' key={id}>
      <div
        className={`pp-accordion__block ${
          isActive ? 'pp-accordion__block--open' : ''
        }`}
        onClick={() =>
          setCurrentlyActiveQuestion(
            questionNumber === currentlyActiveQuestion ? 1 : questionNumber
          )
        }
        key={id}
      >
        <div
          className={
            isActive
              ? 'pp-accordion__green-shape'
              : 'pp-accordion__green-shape--disabled'
          }
        ></div>
        <div
          className={
            isActive ? 'pp-accordion__question--open' : 'pp-accordion__question'
          }
        >
          {question}
        </div>
        <img
          className='pp-accordion__arrow-icon'
          src={isActive ? upFacingArrow : downFacingArrow}
          alt={isActive ? 'Icon to close' : 'Icon to open'}
        />
      </div>
      {isActive && <div className='pp-accordion__answer'>{answer}</div>}
    </div>
  );
};

export default Accordion;
