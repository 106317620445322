/* External */
import React from 'react';

/* Styles */
import './CookieIntroCP.scss';

const CookieIntroCP = () => {
  return (
    <div className='pp-cookie-intro'>
      <h1 className='pp-cookie-intro__title'>Cookie Policy</h1>
      <p className='pp-cookie-intro__intro'>
        All you need to know about cookies in our site.
      </p>
    </div>
  );
};

export default CookieIntroCP;
