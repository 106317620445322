/* External */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

/* Components */
import HeroBanner from './../HeroBanner';
import WhyBeHealthy from '../WhyBeHealthy';
import Vertical from './../Vertical';
import HowItWorks from './../HowItWorks';
import Stats from '../Stats/Stats';
import DeliveryOptions from '../DeliveryOptions';
import Testimonials from './../Testimonials';
import ContactForm from './../ContactForm';
import Footer from './../Footer';

/* Styles */
import './Landing.scss';

/* Others */
import {
  useNavbar,
  useGetCurrentBreakpoint,
} from './../../Utils/Functions/Hooks';

const Landing = ({ }) => {
  const currentBreakpoint = useGetCurrentBreakpoint(useState, useEffect);
  const currentNavBar = useNavbar(useState, currentBreakpoint);

  return (
    <div className='pp-landing'>
      <Helmet>
        <title>BeHealthy Rewards Program | BeHealthy By Globant X</title>
      </Helmet>
      {currentNavBar}
      <div
        id={'HeroBanner'}
        className='pp-landing__section pp-landing__section--hero-banner'
      >
        <span className='pp-landing__section--wrapper'>
          <HeroBanner />
        </span>
      </div>
      <div id={'HowItWorks'} className='pp-landing__section'>
        <span className='pp-landing__section--wrapper'>
          <HowItWorks />
        </span>
      </div>
      <div
        id={'Vertical'}
        className='pp-landing__section pp-landing__section--grey'
      >
        <span className='pp-landing__section--wrapper'>
          <Vertical />
        </span>
      </div>
      <div id={'WhyBeHealthy'} className='pp-landing__section'>
        <span className='pp-landing__section--wrapper'>
          <WhyBeHealthy />
        </span>
      </div>
      <div id={'Stats'} className='pp-landing__section'>
        <span className='pp-landing__section--wrapper'>
          <Stats />
        </span>
      </div>
      <div id={'DeliveryOptions'} className='pp-landing__section'>
        <span className='pp-landing__section--wrapper'>
          <DeliveryOptions />
        </span>
      </div>
      <div id={'Testimonials'} className='pp-landing__section'>
        <span className='pp-landing__section--wrapper'>
          <Testimonials />
        </span>
      </div>
      <div
        id={'ContactForm'}
        className='pp-landing__section pp-landing__section--grey'
      >
        <span className='pp-landing__section--wrapper'>
          <ContactForm />
        </span>
      </div>
      <div
        id={'Footer'}
        className='pp-landing__section pp-landing__section--footer pp-landing__section--black'
      >
        <span className='pp-landing__section--wrapper'>
          <Footer />
        </span>
      </div>
    </div>
  );
};

export default Landing;
